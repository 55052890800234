<mat-radio-group
  *ngFor="let salesOption of salesOptions"
  name="{{ salesOptionsGroupName }}"
>
  <mat-radio-button
    value="{{ salesOption.code }}"
    [disabled]="
      salesOption.state === StateEnum.Invalid ||
      (salesOption.state !== StateEnum.Required &&
        this.oneSalesOptionIsRequired)
    "
    [checked]="
      salesOption.state === StateEnum.Selected ||
      salesOption.state === StateEnum.Required
    "
    (change)="
      salesOption.state !== StateEnum.Invalid && selectSalesOption(salesOption)
    "
    class="option-label"
    data-cy="sales-option"
  >
    {{ salesOption.name }}

    <span
      matTooltip="{{
        'turnkey_solution.configuration.sales_option_tooltip_text' | translate
      }}"
      [matTooltipPosition]="'above'"
    >
      <button
        mat-icon-button
        *ngIf="
          salesOption.description ||
          salesOption.image_uri ||
          salesOption.video_uri
        "
        data-test="so_info_btn"
        (click)="openInfoDialog(salesOption)"
      >
        <mat-icon
          matTooltip="{{
            !salesOption.description &&
            !salesOption.image_uri &&
            !salesOption.video_uri
              ? ('turnkey_solution.common.no_additional_information_available'
                | translate)
              : ''
          }}"
          matTooltipPosition="right"
          matTooltipClass="multiline-tooltip"
          >info_outlined</mat-icon
        >
      </button>
    </span>
  </mat-radio-button>

  <span
    matTooltip="{{ 'turnkey_solution.debug_view.tooltip_text' | translate }}"
    [matTooltipPosition]="'above'"
  >
    <button
      *ngIf="
        (showDebugView$ | async) &&
        (salesOption.state === StateEnum.Invalid ||
          ((salesOption.state === StateEnum.Selected ||
            salesOption.state === StateEnum.Required) &&
            salesOption.selected_by?.length))
      "
      mat-icon-button
      (click)="showSalesOptionStateReason(salesOption)"
      data-test="sl_sc_debugger_button"
      class="sl-sc-debugger"
    >
      <mat-icon>fact_check</mat-icon>
    </button>
  </span>
  <mat-progress-spinner
    [mode]="'indeterminate'"
    color="accent"
    *ngIf="!!(isGroupLoading$ | async) && selectedValue === salesOption.code"
    [diameter]="16"
  ></mat-progress-spinner>

  <br />
  <ng-container *ngFor="let childGroup of salesOption.groups">
    <sl-sc-sales-option-subgroup
      *ngIf="childGroup.type === SalesOptionGroupTypeEnum.Child"
      [salesOptionGroup]="childGroup"
      [userGuid]="userGuid"
      [guide]="guide"
      [selectionType]="childGroup.selection_type"
      [subgroupType]="childGroup.type"
      [sectionName]="sectionName"
    >
    </sl-sc-sales-option-subgroup>
  </ng-container>
</mat-radio-group>
