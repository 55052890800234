<div
  [ngSwitch]="selectionType"
  [ngClass]="{
    'subgroup-container': subgroupType === SalesOptionGroupTypeEnum.Child,
    'subgroup-invalid': noSelectedSubgroupSalesOption
  }"
>
  <sl-sc-sales-option-list-multiple
    *ngSwitchCase="SalesOptionSelectionType.Checkbox"
    [salesOptions]="salesOptionGroup?.sales_options"
    [userGuid]="userGuid"
    [guide]="guide"
    [sectionName]="sectionName"
  >
  </sl-sc-sales-option-list-multiple>
  <sl-sc-sales-option-list
    *ngSwitchCase="SalesOptionSelectionType.RadioButton"
    [salesOptions]="salesOptionGroup?.sales_options"
    [userGuid]="userGuid"
    [guide]="guide"
    [sectionName]="sectionName"
  >
  </sl-sc-sales-option-list>
  <sl-sc-sales-option-dropdown
    *ngSwitchCase="SalesOptionSelectionType.Dropdown"
    [salesOptionGroup]="salesOptionGroup"
    [userGuid]="userGuid"
    [guide]="guide"
  >
  </sl-sc-sales-option-dropdown>
  <sl-sc-sales-option-card
    *ngSwitchCase="SalesOptionSelectionType.Card"
    [salesOptionGroup]="salesOptionGroup"
    [userGuid]="userGuid"
    [guide]="guide"
  ></sl-sc-sales-option-card>
  <sl-sc-sales-option-ral-color
    *ngSwitchCase="SalesOptionSelectionType.RalColor"
    [salesOptions]="salesOptionGroup?.sales_options"
    [userGuid]="userGuid"
    [guide]="guide"
  ></sl-sc-sales-option-ral-color>
</div>
<mat-divider class="subgroup-divider"></mat-divider>
