/**
 * Size containing width and height
 */
export class Size {
  /**
   * Creates a Size object from the given width and height (floats).
   * @param width width of the new size
   * @param height height of the new size
   */
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
  /**
   * Returns a string with the Size width and height
   * @returns a string with the Size width and height
   */
  toString() {
    return `{W: ${this.width}, H: ${this.height}}`;
  }
  /**
   * "Size"
   * @returns the string "Size"
   */
  getClassName() {
    return "Size";
  }
  /**
   * Returns the Size hash code.
   * @returns a hash code for a unique width and height
   */
  getHashCode() {
    let hash = this.width | 0;
    hash = hash * 397 ^ (this.height | 0);
    return hash;
  }
  /**
   * Updates the current size from the given one.
   * @param src the given size
   */
  copyFrom(src) {
    this.width = src.width;
    this.height = src.height;
  }
  /**
   * Updates in place the current Size from the given floats.
   * @param width width of the new size
   * @param height height of the new size
   * @returns the updated Size.
   */
  copyFromFloats(width, height) {
    this.width = width;
    this.height = height;
    return this;
  }
  /**
   * Updates in place the current Size from the given floats.
   * @param width width to set
   * @param height height to set
   * @returns the updated Size.
   */
  set(width, height) {
    return this.copyFromFloats(width, height);
  }
  /**
   * Multiplies the width and height by numbers
   * @param w factor to multiple the width by
   * @param h factor to multiple the height by
   * @returns a new Size set with the multiplication result of the current Size and the given floats.
   */
  multiplyByFloats(w, h) {
    return new Size(this.width * w, this.height * h);
  }
  /**
   * Clones the size
   * @returns a new Size copied from the given one.
   */
  clone() {
    return new Size(this.width, this.height);
  }
  /**
   * True if the current Size and the given one width and height are strictly equal.
   * @param other the other size to compare against
   * @returns True if the current Size and the given one width and height are strictly equal.
   */
  equals(other) {
    if (!other) {
      return false;
    }
    return this.width === other.width && this.height === other.height;
  }
  /**
   * The surface of the Size : width * height (float).
   */
  get surface() {
    return this.width * this.height;
  }
  /**
   * Create a new size of zero
   * @returns a new Size set to (0.0, 0.0)
   */
  static Zero() {
    return new Size(0.0, 0.0);
  }
  /**
   * Sums the width and height of two sizes
   * @param otherSize size to add to this size
   * @returns a new Size set as the addition result of the current Size and the given one.
   */
  add(otherSize) {
    const r = new Size(this.width + otherSize.width, this.height + otherSize.height);
    return r;
  }
  /**
   * Subtracts the width and height of two
   * @param otherSize size to subtract to this size
   * @returns a new Size set as the subtraction result of  the given one from the current Size.
   */
  subtract(otherSize) {
    const r = new Size(this.width - otherSize.width, this.height - otherSize.height);
    return r;
  }
  /**
   * Scales the width and height
   * @param scale the scale to multiply the width and height by
   * @returns a new Size set with the multiplication result of the current Size and the given floats.
   */
  scale(scale) {
    return new Size(this.width * scale, this.height * scale);
  }
  /**
   * Creates a new Size set at the linear interpolation "amount" between "start" and "end"
   * @param start starting size to lerp between
   * @param end end size to lerp between
   * @param amount amount to lerp between the start and end values
   * @returns a new Size set at the linear interpolation "amount" between "start" and "end"
   */
  static Lerp(start, end, amount) {
    const w = start.width + (end.width - start.width) * amount;
    const h = start.height + (end.height - start.height) * amount;
    return new Size(w, h);
  }
}
