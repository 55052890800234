import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  ArcRotateCamera,
  Camera,
  Color3,
  Color4,
  Engine,
  HemisphericLight,
  Scene,
  SceneLoader,
  Tools,
  Vector3,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import { Store, select } from '@ngrx/store';
import { filterTruthy } from '@shared-lib/rxjs';
import { SlScConfigurationActions } from '../store/configuration.actions';
import { SlScConfigurationSelectors } from '../store/configuration.selectors';

@Component({
  selector: 'sl-sc-product-viewer',
  standalone: true,
  templateUrl: './product-viewer.component.html',
  styleUrls: ['./product-viewer.component.scss'],
})
export class SlScProductViewerComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() public isGoToSummaryClicked: boolean;
  private canvas: HTMLCanvasElement;
  private engine: Engine;
  private scene: Scene;
  private camera: Camera;
  selectedColor: [number, number, number] | undefined;
  carModelMeshesMap = new Map<string, string>([
    ['TB_fixed', 'TB_tele-TEST_primitive0'],
    ['TB_tele-TEST', 'TB_tele-TEST_primitive0'],
    ['TOW_50', 'TOW_40_primitive0'],
    ['TOW_40', 'TOW_40_primitive0'],
    ['TB_15-pin', 'TB_7-pin_primitive0'],
    ['TB_7-pin', 'TB_7-pin_primitive0'],
    ['TB_duo', 'TB_palm_primitive0'],
    ['TB_palm', 'TB_palm_primitive0'],
  ]);
  constructor(
    private elRef: ElementRef,
    private store: Store,
  ) {}
  ngOnInit() {
    this.changeTrailerColor();
    this.toggleTrailerDisplay();
  }
  ngAfterViewInit() {
    this.canvas = this.elRef.nativeElement.querySelector(
      '#canvas',
    ) as HTMLCanvasElement;
    this.engine = new Engine(this.canvas, true);
    this.engine.displayLoadingUI();
    this.createScene();
    this.engine.runRenderLoop(() => {
      this.scene.render();
      this.engine.hideLoadingUI();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.isGoToSummaryClicked.previousValue !== undefined &&
      changes.isGoToSummaryClicked.previousValue !== true &&
      changes.isGoToSummaryClicked.currentValue === true
    ) {
      this.takeAPicture();
    }
  }
  createScene() {
    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0.97, 0.98, 0.98);
    this.camera = new ArcRotateCamera(
      'camera',
      -Math.PI / 0.9,
      Math.PI / 2.5,
      10,
      new Vector3(0, 0, 0),
    );
    this.camera.storeState();
    this.camera.attachControl(this.canvas, true);
    (this.camera as any).wheelPrecision = 80;
    this.camera.minZ = 0.5; // how much we can zoom in; affects the performance - more zoom in more detailed objects
    (this.camera as any).lowerRadiusLimit = 3; // how far we can zoom out
    (this.camera as any).upperRadiusLimit = 6; // how close we can zoom in
    (this.camera as any).panningSensibility = 0; // how much off the screen can we navigate and move our model; 0 disables it
    (this.camera as any).upperBetaLimit = 1.57; // disable rotation underneath the truck
    const light = new HemisphericLight('light', new Vector3(1, 1, 1));
    light.intensity = 1.5;
    const root3d = '../../../../assets/3d/';
    const porscheCar = 'scene.glb';
    SceneLoader.ImportMesh('', root3d, porscheCar, this.scene, () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    });
    return this.scene;
  }
  toggleTrailerDisplay() {
    this.store
      .pipe(
        select(SlScConfigurationSelectors.selectedSalesOptionName),
        filterTruthy(),
      )
      .subscribe((selectedSalesOptionName) => {
        const selectedSalesOption = this.carModelMeshesMap.get(
          selectedSalesOptionName,
        );
        if (!selectedSalesOption) return;
        const meshToToggle =
          this.scene?.getMeshByName(selectedSalesOption)?.parent;
        if (meshToToggle) {
          meshToToggle.setEnabled(!meshToToggle.isEnabled());
        } else {
          console.warn(`Unable to find mesh named ${selectedSalesOption}`);
        }
      });
  }
  togglePartDisplay() {
    const mesh = this.scene.getMeshByName('Truck_Trailer');
    if (mesh) {
      mesh.setEnabled(mesh.isEnabled() ? false : true);
    }
  }
  changeTrailerColor() {
    this.store
      .pipe(select(SlScConfigurationSelectors.colorInfo), filterTruthy())
      .subscribe((colorInfo) => {
        this.selectedColor = this.hexToRGB(colorInfo[0]) ?? undefined;
        const meshTruck = this.scene?.getMeshByName('carPaint');
        const meshTrailer = this.scene?.getMeshByName('Truck_Trailer');
        if (
          meshTruck &&
          this.selectedColor !== undefined &&
          colorInfo[1]?.startsWith('TRUCK')
        ) {
          meshTruck.renderOverlay = true;
          meshTruck.overlayAlpha = 1;
          meshTruck.overlayColor = new Color3(
            this.selectedColor[0],
            this.selectedColor[1],
            this.selectedColor[2],
          );
        } else if (
          meshTrailer &&
          this.selectedColor !== undefined &&
          colorInfo[1]?.startsWith('CRANE')
        ) {
          meshTrailer.renderOverlay = true;
          meshTrailer.overlayAlpha = 1;
          meshTrailer.overlayColor = new Color3(
            this.selectedColor[0],
            this.selectedColor[1],
            this.selectedColor[2],
          );
        }
      });
  }
  hexToRGB(hex: string | undefined): [number, number, number] | undefined {
    // Remove '#' if present
    if (hex !== undefined) {
      hex = hex.replace('#', '');
      // Parse hex values
      let r = 0;
      let g = 0;
      let b = 0;
      if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
      } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
      }
      // Normalize RGB values
      const red = r / 255;
      const green = g / 255;
      const blue = b / 255;
      // Construct the RGBA string
      return [red, green, blue];
    }
  }
  takeAPicture(): void {
    this.camera?.restoreState();
    this.scene.onReadyObservable.add(() => {
      Tools.CreateScreenshot(
        this.engine,
        this.camera,
        { precision: 1.0 },
        (data) => {
          this.store.dispatch(
            SlScConfigurationActions.saveModelImage({ modelImage: data }),
          );
        },
      );
    });
  }
  ngOnDestroy(): void {
    if (this.engine) {
      this.engine.stopRenderLoop();
    }
  }
}
