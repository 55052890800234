import { GLTFLoader, ArrayItem } from "../glTFLoader.js";
const NAME = "EXT_texture_webp";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Vendor/EXT_texture_webp/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class EXT_texture_webp {
  /**
   * @internal
   */
  constructor(loader) {
    /** The name of this extension. */
    this.name = NAME;
    this._loader = loader;
    this.enabled = loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  _loadTextureAsync(context, texture, assign) {
    return GLTFLoader.LoadExtensionAsync(context, texture, this.name, (extensionContext, extension) => {
      const sampler = texture.sampler == undefined ? GLTFLoader.DefaultSampler : ArrayItem.Get(`${context}/sampler`, this._loader.gltf.samplers, texture.sampler);
      const image = ArrayItem.Get(`${extensionContext}/source`, this._loader.gltf.images, extension.source);
      return this._loader._createTextureAsync(context, sampler, image, babylonTexture => {
        assign(babylonTexture);
      }, undefined, !texture._textureInfo.nonColorData);
    });
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new EXT_texture_webp(loader));
