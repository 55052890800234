import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_mesh_quantization";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_mesh_quantization/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_mesh_quantization {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    this.enabled = loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {}
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_mesh_quantization(loader));
