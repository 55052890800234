<h1 mat-dialog-title>
  {{ 'turnkey_solution.summary.contact_form.caption' | translate }}
</h1>
<div mat-dialog-content #scrollContainer>
  <p class="form-details">
    {{ formDetails }}
  </p>
  <form
    [formGroup]="contactForm"
    class="form-grid sc-contact-form"
    #contactFormContainer
  >
    <mat-card class="sc-contact-card">
      <mat-card-content>
        <h4>
          {{
            'turnkey_solution.summary.contact_form.personal_information'
              | translate
          }}
        </h4>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label>{{
            'turnkey_solution.summary.contact_form.salutation' | translate
          }}</mat-label>
          <mat-select formControlName="salutation" id="formSalutation">
            <mat-option
              *ngFor="let salutation of salutations$ | async"
              value="{{ salutation.value }}"
              data-cy="form-salutation-item"
            >
              {{ salutation.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactForm.controls.salutation.invalid">{{
            'turnkey_solution.common.required' | translate
          }}</mat-error>
        </mat-form-field>
        <div fxLayoutAlign="space-between" fxLayout.lt-sm="column">
          <mat-form-field fxFlex="48" fxFlex.lt-sm="100" hideRequiredMarker>
            <mat-label>{{
              'turnkey_solution.summary.contact_form.first_name' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="first_name"
              autocomplete="off"
              data-cy="form-first-name"
            />
            <mat-error *ngIf="contactForm.controls.first_name.invalid">
              {{ getInputErrorMessage(contactForm.controls.first_name) }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="48" fxFlex.lt-sm="100" hideRequiredMarker>
            <mat-label>{{
              'turnkey_solution.summary.contact_form.last_name' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="last_name"
              autocomplete="off"
              data-cy="form-last-name"
            />
            <mat-error *ngIf="contactForm.controls.last_name.invalid">
              {{ getInputErrorMessage(contactForm.controls.last_name) }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="full-width">
          <mat-label
            >{{
              'turnkey_solution.summary.contact_form.job_title' | translate
            }}
            ({{
              'turnkey_solution.summary.contact_form.optional' | translate
            }})</mat-label
          >
          <input matInput formControlName="job_title" autocomplete="off" />
          <mat-error *ngIf="contactForm.controls.job_title.invalid">
            {{
              getInputCharRestrictionErrorMessage(
                contactForm.controls.job_title
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label>{{
            'turnkey_solution.summary.contact_form.company_name' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="company_name"
            autocomplete="off"
            data-cy="form-company-name"
          />
          <mat-error *ngIf="contactForm.controls.company_name.invalid">
            {{ getInputErrorMessage(contactForm.controls.company_name) }}
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="sc-contact-card">
      <mat-card-content>
        <h4>
          {{
            'turnkey_solution.summary.contact_form.contact_information'
              | translate
          }}
        </h4>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label>{{
            'turnkey_solution.summary.contact_form.email_address' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="email_address"
            autocomplete="off"
            data-cy="form-email"
          />
          <mat-error *ngIf="contactForm.controls.email_address.invalid">
            <span
              *ngIf="contactForm.controls.email_address.hasError('required')"
              >{{ 'turnkey_solution.common.required' | translate }}</span
            >
            <span
              *ngIf="contactForm.controls.email_address.hasError('email')"
              >{{ 'turnkey_solution.common.invalid_email' | translate }}</span
            >
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label
            >{{
              'turnkey_solution.summary.contact_form.phone_number' | translate
            }}
            ({{
              'turnkey_solution.summary.contact_form.optional' | translate
            }})</mat-label
          >
          <input matInput formControlName="phone_number" autocomplete="off" />
          <mat-error *ngIf="contactForm.controls.phone_number.invalid">
            {{ 'turnkey_solution.common.invalid_phone' | translate }}
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="sc-contact-card">
      <mat-card-content>
        <h4>
          {{ 'turnkey_solution.summary.contact_form.address' | translate }}
        </h4>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label>{{
            'turnkey_solution.summary.contact_form.country' | translate
          }}</mat-label>
          <mat-select formControlName="country" data-cy="form-country">
            <mat-option
              *ngFor="let country of countries$ | async"
              value="{{ country.value }}"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactForm.controls.country.invalid">{{
            'turnkey_solution.common.required' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label>{{
            'turnkey_solution.summary.contact_form.street' | translate
          }}</mat-label>
          <input
            matInput
            formControlName="street"
            autocomplete="off"
            data-cy="form-street"
          />
          <mat-error *ngIf="contactForm.controls.street.invalid">
            {{ getInputErrorMessage(contactForm.controls.street) }}
          </mat-error>
        </mat-form-field>
        <div fxLayoutAlign="space-between" fxLayout.lt-sm="column">
          <mat-form-field fxFlex="28" fxFlex.lt-sm="100" hideRequiredMarker>
            <mat-label>{{
              'turnkey_solution.summary.contact_form.postal_code' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="postal_code"
              autocomplete="off"
              data-cy="form-postal-code"
            />
            <mat-error *ngIf="contactForm.controls.postal_code.invalid">
              {{ getInputErrorMessage(contactForm.controls.postal_code) }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="68" fxFlex.lt-sm="100" hideRequiredMarker>
            <mat-label>{{
              'turnkey_solution.summary.contact_form.city' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="city"
              autocomplete="off"
              data-cy="form-city"
            />
            <mat-error *ngIf="contactForm.controls.city.invalid">
              {{ getInputErrorMessage(contactForm.controls.city) }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="sc-contact-card">
      <mat-card-content>
        <h4>
          {{ 'turnkey_solution.summary.contact_form.message' | translate }}
        </h4>
        <mat-form-field class="full-width" hideRequiredMarker>
          <mat-label
            >{{
              'turnkey_solution.summary.contact_form.message' | translate
            }}
            ({{
              'turnkey_solution.summary.contact_form.optional' | translate
            }})</mat-label
          >
          <textarea
            matInput
            formControlName="message"
            autocomplete="off"
          ></textarea>
          <mat-error>{{
            'form_char_restriction'
              | translate
                : {
                    limit: messageCharLimit,
                    count:
                      (contactForm.controls.message.value?.length ??
                        messageCharLimit) - messageCharLimit
                  }
          }}</mat-error>
          <mat-hint align="end"
            >{{ contactForm.controls.message.value?.length }} /
            {{ messageCharLimit }}</mat-hint
          >
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <div class="relative-parent">
      <mat-checkbox
        required
        class="full-width"
        formControlName="gdpr_consent_given"
        ngDefaultControl
        data-cy="form-gdpr"
      >
        {{ 'turnkey_solution.common.gdpr_consent' | translate }}
        <mat-error
          *ngIf="
            contactForm.controls.gdpr_consent_given.invalid &&
            contactForm.controls.gdpr_consent_given.dirty
          "
        >
          {{ 'turnkey_solution.common.required_field' | translate }}
        </mat-error>
      </mat-checkbox>
    </div>
    <input hidden matInput formControlName="base_url" />
  </form>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'turnkey_solution.common.close' | translate }}
  </button>
  @if (contactForm.valid) {
    <button
      mat-raised-button
      color="accent"
      [disabled]="!!formSubmitting"
      [dsButtonPending]="formSubmitting"
      disableRipple="true"
      (click)="submitContactForm()"
      data-cy="submit-quote"
      [gaClickListener]="Analytics.ContactFormSubmit"
    >
      {{ 'turnkey_solution.common.submit' | translate }}
    </button>
  } @else {
    <button
      mat-raised-button
      color="accent"
      (click)="submitContactForm()"
      disableRipple="true"
    >
      {{ 'turnkey_solution.common.submit' | translate }}
    </button>
  }
</mat-dialog-actions>
