import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "MSFT_sRGBFactors";
/** @internal */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class MSFT_sRGBFactors {
  /** @internal */
  constructor(loader) {
    /** @internal */
    this.name = NAME;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /** @internal */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtraAsync(context, material, this.name, (extraContext, extra) => {
      if (extra) {
        if (!(babylonMaterial instanceof PBRMaterial)) {
          throw new Error(`${extraContext}: Material type not supported`);
        }
        const promise = this._loader.loadMaterialPropertiesAsync(context, material, babylonMaterial);
        const useExactSrgbConversions = babylonMaterial.getScene().getEngine().useExactSrgbConversions;
        if (!babylonMaterial.albedoTexture) {
          babylonMaterial.albedoColor.toLinearSpaceToRef(babylonMaterial.albedoColor, useExactSrgbConversions);
        }
        if (!babylonMaterial.reflectivityTexture) {
          babylonMaterial.reflectivityColor.toLinearSpaceToRef(babylonMaterial.reflectivityColor, useExactSrgbConversions);
        }
        return promise;
      }
      return null;
    });
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new MSFT_sRGBFactors(loader));
