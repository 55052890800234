import { AbstractMesh } from "../Meshes/abstractMesh.js";
Object.defineProperty(AbstractMesh.prototype, "decalMap", {
  get: function () {
    return this._decalMap;
  },
  set: function (decalMap) {
    this._decalMap = decalMap;
  },
  enumerable: true,
  configurable: true
});
