<h1 mat-dialog-title>
  {{ 'turnkey_solution.debug_view.configuration_dialog.title' | translate }}
</h1>
<div mat-dialog-content *ngIf="salesOptions.length" data-cy="so-disabled-by">
  <div *ngFor="let salesOption of salesOptions">
    <ng-container *ngIf="salesOption.selected_by?.length">
      <p>
        {{
          'turnkey_solution.debug_view.configuration_dialog.selected_by_combination'
            | translate
        }}:
      </p>
      <ng-container *ngFor="let item of salesOption.selected_by">
        <ng-container
          *ngTemplateOutlet="salesOptionTemp; context: { $implicit: item }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="salesOption.disabled_by?.length">
      <p>
        {{
          'turnkey_solution.debug_view.configuration_dialog.disabled_by_combination'
            | translate
        }}:
      </p>
      <ng-container *ngFor="let item of salesOption.disabled_by">
        <ng-container
          *ngTemplateOutlet="salesOptionTemp; context: { $implicit: item }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'turnkey_solution.common.close' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #salesOptionTemp let-item>
  <ul>
    <li *ngIf="item.type === 'SalesOption'">
      <strong
        >{{
          'turnkey_solution.debug_view.configuration_dialog.sales_option_label'
            | translate
        }}: </strong
      >{{ item.sales_option?.name }}
    </li>
    <li *ngIf="item.type === 'Answer'">
      <strong
        >{{ 'turnkey_solution.debug_view.question_label' | translate }}:
      </strong>
      {{ item.answer?.question_heading }}
      <br />
      <strong
        >{{ 'turnkey_solution.debug_view.answer_label' | translate }}: </strong
      >{{ item.answer?.heading }}
    </li>
  </ul>
</ng-template>
