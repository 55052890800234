import { RegisterClass } from "../../../Misc/typeStore.js";
import { NodeGeometryBlockConnectionPointTypes } from "../Enums/nodeGeometryConnectionPointTypes.js";
import { NodeGeometryBlock } from "../nodeGeometryBlock.js";
/**
 * Block used to get the length of a vector
 */
export class GeometryLengthBlock extends NodeGeometryBlock {
  /**
   * Creates a new GeometryLengthBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name);
    this.registerInput("value", NodeGeometryBlockConnectionPointTypes.AutoDetect);
    this.registerOutput("output", NodeGeometryBlockConnectionPointTypes.Float);
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Int);
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Float);
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Matrix);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "GeometryLengthBlock";
  }
  /**
   * Gets the value input component
   */
  get value() {
    return this._inputs[0];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock() {
    if (!this.value.isConnected) {
      this.output._storedFunction = null;
      this.output._storedValue = null;
      return;
    }
    this.output._storedFunction = state => {
      const value = this.value.getConnectedValue(state);
      return value.length();
    };
    return this;
  }
}
RegisterClass("BABYLON.GeometryLengthBlock", GeometryLengthBlock);
