import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import {
  Guide,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionSelectionType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedSelectors } from '@sales-libs/shared/feature';
import { populateProductLineEnum } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Subject, take, takeUntil } from 'rxjs';
import { SlScConfigurationSelectors } from '../../store';
import { SlScSalesOptionCardComponent } from '../sales-option-card/sales-option-card.component';
import { SlScSalesOptionDropdownComponent } from '../sales-option-dropdown/sales-option-dropdown.component';
import { SlScSalesOptionListMultipleComponent } from '../sales-option-list-multiple/sales-option-list-multiple.component';
import { SlScSalesOptionListComponent } from '../sales-option-list/sales-option-list.component';
import { SlScSalesOptionRalColorComponent } from '../sales-option-ral-color/sales-option-ral-color.component';
import { SlScSalesOptionSliderComponent } from '../sales-option-slider/sales-option-slider.component';
import { SlScSalesOptionSubgroupComponent } from '../sales-option-subgroup/sales-option-subgroup.component';

export const imports = [
  SlScSalesOptionListComponent,
  SlScSalesOptionListMultipleComponent,
  SlScSalesOptionSubgroupComponent,
  SlScSalesOptionSliderComponent,
  SlScSalesOptionCardComponent,
  SlScSalesOptionRalColorComponent,
  SlScSalesOptionDropdownComponent,
  MatExpansionModule,
  CommonModule,
];

@Component({
  selector: 'sl-sc-sales-option-group',
  standalone: true,
  imports,
  templateUrl: './sales-option-group.component.html',
  styleUrls: ['./sales-option-group.component.scss'],
})
export class SlScSalesOptionGroupComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  salesOptionGroup: SalesOptionGroup;
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  @Input()
  sectionName: string;
  selectedSalesOption: string | undefined | null;
  selectedMultipleSalesOptions: (string | null | undefined)[] = [];
  readonly StateEnum = SalesOptionState;
  readonly SalesOptionGroupType = SalesOptionGroupType;
  readonly SalesOptionSelectionType = SalesOptionSelectionType;
  noSelectedSalesOptionOrAttribute: boolean;
  selectedSliderValues: { [name: string]: number | undefined };
  private readonly destroy$ = new Subject<void>();
  private readonly AttributeBasedGroupType = 'AttributeBased';
  productLineEnum: { [key: string]: string } = {};

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getProductLineEnum();
    this.setSalesOptionGroupDescription();
  }

  ngOnChanges(): void {
    this.setSalesOptionGroupDescription();
  }

  getProductLineEnum(): void {
    this.store
      .pipe(select(ScSharedSelectors.userGuides), filterTruthy(), take(1))
      .subscribe((guides) => {
        this.productLineEnum = populateProductLineEnum(guides, false);
      });
  }

  getSelectedRegularSalesOption(): string {
    return (
      this.salesOptionGroup?.sales_options?.find(
        (salesOption) =>
          salesOption.state === this.StateEnum.Selected ||
          salesOption.state === this.StateEnum.Required,
      )?.name ?? ''
    );
  }

  setSalesOptionGroupDescription() {
    /* INFO: If more than one Attribute Based Group is introduced, consider refactoring this condition
       (set sales option group description only for Platform Type group) */
    if (
      this.salesOptionGroup?.type === this.AttributeBasedGroupType &&
      this.guide?.name === this.productLineEnum.TailLift
    ) {
      this.selectedSalesOption = this.getSelectedRegularSalesOption() ?? '';
    }
    this.checkIfAnySalesOptionIsSelected();
  }

  checkIfAnySalesOptionIsSelected() {
    if (
      this.salesOptionGroup?.subgroups &&
      this.salesOptionGroup?.subgroups.length > 0
    ) {
      this.noSelectedSalesOptionOrAttribute =
        this.salesOptionGroup?.subgroups?.filter((subgroup) =>
          subgroup.sales_options?.filter(
            (salesOption) =>
              salesOption.state === this.StateEnum.Selected ||
              salesOption.state === this.StateEnum.Required,
          ),
        ).length === 0;
    } else if (
      this.salesOptionGroup?.type !== SalesOptionGroupType.AttributeBased
    ) {
      this.noSelectedSalesOptionOrAttribute =
        this.salesOptionGroup?.sales_options?.filter(
          (singleSalesOption) =>
            singleSalesOption.state === this.StateEnum.Selected ||
            singleSalesOption.state === this.StateEnum.Required,
        ).length === 0;
    } else if (
      this.salesOptionGroup?.type === SalesOptionGroupType.AttributeBased
    ) {
      this.getSelectedSliderValuesAndCheckIfValid();
    }
  }

  getSelectedSliderValuesAndCheckIfValid(): void {
    this.store
      .pipe(
        select(SlScConfigurationSelectors.selectedSliderValues),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((sliderValues) => {
        this.selectedSliderValues = sliderValues ?? {};
        if (
          !this.selectedSliderValues.height ||
          !this.selectedSliderValues.width
        ) {
          this.noSelectedSalesOptionOrAttribute = true;
        } else {
          this.noSelectedSalesOptionOrAttribute = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
