import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Guide,
  SalesOption,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { SlScConfigurationActions } from '../../store';
import { SlScSalesOptionSubgroupComponent } from '../sales-option-subgroup/sales-option-subgroup.component';

export const imports = [
  CommonModule,
  MatRadioModule,
  MatFormFieldModule,
  TranslateModule,
  SlScSalesOptionSubgroupComponent,
  FlexModule,
];

@Component({
  selector: 'sl-sc-sales-option-card',
  standalone: true,
  imports,
  templateUrl: './sales-option-card.component.html',
  styleUrls: ['./sales-option-card.component.scss'],
})
export class SlScSalesOptionCardComponent implements OnInit {
  @Input()
  salesOptionGroup: SalesOptionGroup;
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  selectedLanguage: string;
  isDisabled: boolean | undefined;

  readonly StateEnum = SalesOptionState;
  readonly SalesOptionGroupTypeEnum = SalesOptionGroupType;
  oneSalesOptionIsRequired = false;
  oneSalesOptionInGroupSelected = false;

  constructor(
    private store: Store,
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getLanguageFromURL();
    this.prepareData();
  }

  getLanguageFromURL(): void {
    this.selectedLanguage = this.route.snapshot.params?.language;
  }

  prepareData(): void {
    if (
      this.salesOptionGroup?.sales_options?.some(
        (salesOption) => salesOption.state === this.StateEnum.Required,
      )
    )
      this.oneSalesOptionIsRequired = true;

    this.isDisabled = this.salesOptionGroup?.sales_options?.every(
      (salesOption: SalesOption) =>
        salesOption.state === this.StateEnum.Invalid ||
        (salesOption.state !== this.StateEnum.Required &&
          this.oneSalesOptionIsRequired),
    );

    if (
      this.salesOptionGroup?.sales_options?.some(
        (salesOption) => salesOption.state === this.StateEnum.Selected,
      )
    )
      this.oneSalesOptionInGroupSelected = true;
  }

  selectSalesOption(salesOption: SalesOption) {
    const selectedSalesOptionIds = { sales_options_ids: [salesOption.id] };

    this.store.dispatch(
      SlScConfigurationActions.saveSelectedSalesOption({
        guideId: this.guide?.id ?? 0,
        userGuid: this.userGuid,
        language: this.selectedLanguage,
        selectedSalesOptions: selectedSalesOptionIds,
        selectedSalesOptionName: salesOption.code,
      }),
    );
  }
}
