<div class="summary-screen">
  <ds-spotlight id="sl-sc-welcome-spotlight" *ngIf="displaySpotlight">
    <h3>{{ 'turnkey_solution.summary.spotlight_main_title' | translate }}</h3>
    {{ 'turnkey_solution.summary.spotlight_main_text' | translate }}
  </ds-spotlight>

  <div *ngIf="!stepName && isSC" class="logo-container">
    <sl-shared-sc-product-line-icons
      [productLineName]="scProductLineName"
      [selectedModelName]="this.userConfiguration?.selected_model"
      [urlFriendlyProductLineEnum]="urlFriendlyProductLineEnum"
      [environmentLink]="scUrl"
      (startNewConfiguration)="handleStartNewConfiguration()"
    ></sl-shared-sc-product-line-icons>
  </div>

  <div
    class="summary-upper-wrap"
    fxLayout="row wrap"
    fxLayoutAlign="space-between"
  >
    <ng-container *ngIf="(isLoading$ | async) === false; else priceLoading">
      <h2 [ngSwitch]="scProductLineName">
        <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TailLift">
          <span *ngIf="userConfiguration$ | async as userConfiguration">{{
            userConfiguration.selected_model
          }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane">
          {{ 'turnkey_solution.common.model_name.timber_crane' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber">
          {{ 'turnkey_solution.common.model_name.short_timber' | translate }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ 'turnkey_solution.common.model_name.default' | translate }}
        </ng-container>
      </h2>
    </ng-container>
    <div
      class="summary-currency-wrap"
      *ngIf="supportedCurrencies$ | async as supportedCurrencies"
    >
      <ng-container
        *ngIf="(isLoading$ | async) === false; else dropdownLoading"
      >
        <mat-select
          [(value)]="selectedCurrency"
          data-test="sc_currency_dropdown"
          [disabled]="supportedCurrencies.length <= 1"
          (selectionChange)="changeSelectedCurrency(selectedCurrency)"
        >
          <mat-option
            *ngFor="let supportedCurrency of supportedCurrencies"
            [value]="supportedCurrency.code"
          >
            {{ supportedCurrency.code ?? '' | slScCurrencySymbol }}
            {{ supportedCurrency.name }}
          </mat-option>
        </mat-select>
      </ng-container>
    </div>
  </div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between start"
    class="summary-upper-wrap"
  >
    <div
      fxLayoutAlign="center center"
      fxFlex="65%"
      fxFlex.lt-md="100%"
      class="fixed-height summary-carousel"
    >
      <ds-image-carousel
        [slides]="
          slides
            ? slides
            : [
                {
                  src: '../../assets/img/configuration/placeholder-configuration-summary.png'
                }
              ]
        "
        [height]="'100%'"
        class="full-width full-height"
        [autoSlide]="0"
      ></ds-image-carousel>
    </div>

    <div class="summary-upper-wrap__info" fxFlex="30%" fxFlex.lt-md="100%">
      <p>{{ 'turnkey_solution.summary.your_solution' | translate }}</p>

      <ng-container *ngIf="(isLoading$ | async) === false; else priceLoading">
        <h2
          fxLayoutAlign="space-between baseline"
          data-cy="model-name"
          [ngSwitch]="scProductLineName"
        >
          <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TailLift">
            <span *ngIf="userConfiguration$ | async as userConfiguration">{{
              userConfiguration.selected_model
            }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane">
            {{ 'turnkey_solution.common.model_name.timber_crane' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber">
            {{ 'turnkey_solution.common.model_name.short_timber' | translate }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ 'turnkey_solution.common.model_name.default' | translate }}
          </ng-container>
          <ng-container *ngIf="userConfiguration$ | async as userConfiguration">
            <span
              *ngIf="
                userConfiguration.display_unit_prices &&
                userConfiguration.model_price
              "
              >{{
                userConfiguration.model_price.total_price
                  | currency: userConfiguration.currency_code
              }}</span
            >
          </ng-container>
        </h2>
      </ng-container>

      <div
        fxLayout="column"
        fxLayout.lt-sm="row"
        fxLayoutAlign="start"
        fxLayoutAlign.lt-sm="space-between baseline"
      >
        <p class="summary-upper-wrap__price">
          {{ 'turnkey_solution.summary.total_price' | translate }}
        </p>
        <ng-container *ngIf="(isLoading$ | async) === false; else priceLoading">
          <h2
            class="summary-configuration__price-weight-number"
            *ngIf="userConfiguration$ | async as userConfiguration"
            data-cy="total-price"
          >
            {{
              userConfiguration.total_price
                | currency: userConfiguration.currency_code
            }}
          </h2>
        </ng-container>
      </div>

      <ng-container
        *ngIf="
          userConfiguration$ | async as userConfiguration;
          else descriptionLoading
        "
      >
        <ul *ngIf="userConfiguration.model_description" class="model-details">
          <li
            *ngFor="let description of userConfiguration.model_description"
            [innerHtml]="description | slScDescriptionDecoration"
          ></li>
        </ul>
      </ng-container>

      <div fxLayout="row wrap" class="summary-upper-wrap__info-btns">
        <ng-container
          *ngIf="(isLoading$ | async) === false; else dropdownLoading"
        >
          <button
            mat-stroked-button
            type="button"
            [matMenuTriggerFor]="shareMenu"
            class="sc-summary-menu-btn"
            data-test="sc-summary-menu"
            data-cy="sc-summary-menu"
          >
            <mat-icon>share</mat-icon>
            {{ 'turnkey_solution.summary.button_share' | translate }}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #shareMenu="matMenu" [xPosition]="'before'">
            <button
              mat-menu-item
              disableRipple="true"
              (click)="openExportDialog()"
              data-cy="sc-summary-menu-item"
            >
              <mat-icon>download</mat-icon>
              {{ 'turnkey_solution.summary.button_export' | translate }}
            </button>
            <button
              mat-menu-item
              disableRipple="true"
              (click)="onCopy()"
              data-cy="sc-summary-menu-item"
              [gaClickListener]="Analytics.UrlShare"
            >
              <mat-icon class="link-icon">link</mat-icon>
              {{ 'turnkey_solution.common.copy_link' | translate }}
            </button>
            <button
              mat-menu-item
              disableRipple="true"
              (click)="$event.preventDefault(); sendEmail()"
              data-cy="sc-summary-menu-item"
              [gaClickListener]="Analytics.UrlShare"
            >
              <mat-icon>email</mat-icon>
              {{ 'turnkey_solution.summary.email' | translate }}
            </button>
          </mat-menu>
        </ng-container>

        <ng-container
          *ngIf="
            this.scProductLineName === urlFriendlyProductLineEnum.TailLift &&
            displaySpotlight
          "
        >
          @if ((isLoading$ | async) === false) {
            <button
              mat-raised-button
              color="accent"
              (click)="openContactFormDialog()"
              data-test="sc-summary-button"
              data-cy="sc-quote-button"
              [spotlight]="requestQuote"
            >
              <mat-icon>contact_mail</mat-icon>
              {{ 'turnkey_solution.summary.button_contact_form' | translate }}
            </button>

            <ds-spotlight
              #requestQuote
              position="left"
              [tags]="['position']"
              id="request-quote-spotlight"
            >
              {{
                'turnkey_solution.summary.spotlight_contact_form' | translate
              }}
            </ds-spotlight>
          } @else {
            <ng-container *ngTemplateOutlet="dropdownLoading"></ng-container>
          }
        </ng-container>
        <ng-container
          *ngIf="
            this.scProductLineName === urlFriendlyProductLineEnum.TailLift &&
            !displaySpotlight
          "
        >
          <button
            mat-raised-button
            color="accent"
            (click)="openContactFormDialog()"
            data-test="sc-summary-button"
            data-cy="sc-quote-button"
          >
            <mat-icon>contact_mail</mat-icon>
            {{ 'turnkey_solution.summary.button_contact_form' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="summary-links">
    <mat-divider></mat-divider>
    <div fxLayout="row wrap" [fxLayoutGap.gt-xs]="2 | dsSpacing">
      <mat-card
        class="summary-links__card"
        fxLayout="column wrap"
        fxLayoutAlign="space-between"
        fxFlex.lt-sm="100%"
      >
        <mat-card-content>
          <div fxLayout="row">
            <mat-icon class="summary-links__icon">info</mat-icon>
            <div>
              <h3>
                {{
                  'turnkey_solution.summary.product_website_title' | translate
                }}
              </h3>
              <p>
                {{
                  'turnkey_solution.summary.product_website_text' | translate
                }}
              </p>
            </div>
          </div>
          <a
            [href]="userConfiguration.external_uri"
            target="_blank"
            rel="noopener noreferrer"
            class="text-right"
            *ngIf="userConfiguration$ | async as userConfiguration"
            data-cy="palfinger-link"
          >
            {{ 'turnkey_solution.summary.product_website_link' | translate }}
          </a>
        </mat-card-content>
      </mat-card>
      <mat-card
        class="summary-links__card"
        fxLayout="column wrap"
        fxLayoutAlign="space-between"
        fxFlex.lt-sm="100%"
      >
        @if (
          this.scProductLineName !== urlFriendlyProductLineEnum.TimberCrane ||
          this.scProductLineName === urlFriendlyProductLineEnum.ShortTimber
        ) {
          <mat-card-content>
            <div fxLayout="row">
              <mat-icon class="summary-links__icon">fact_check</mat-icon>
              <div>
                <h3>
                  {{
                    'turnkey_solution.summary.feasibility_study_title'
                      | translate
                  }}
                </h3>
                <p>
                  {{
                    'turnkey_solution.summary.feasibility_study_text'
                      | translate
                  }}
                </p>
              </div>
            </div>
            <a
              href="https://tail-lift-study.palfinger.com/project"
              target="_blank"
              rel="noopener noreferrer"
              class="text-right"
              data-cy="palfinger-link"
            >
              {{
                'turnkey_solution.summary.feasibility_study_link' | translate
              }}
            </a>
          </mat-card-content>
        } @else {
          <mat-card-content>
            <div fxLayout="row">
              <mat-icon
                class="summary-links__icon"
                fontSet="pd"
                fontIcon="pd-documents"
              ></mat-icon>
              <div>
                <h3>
                  {{ 'turnkey_solution.summary.brochure_title' | translate }}
                </h3>
                <p>
                  {{ 'turnkey_solution.summary.brochure_text' | translate }}
                </p>
              </div>
            </div>
            <a
              [href]="userConfiguration?.brochure_uri"
              rel="noopener noreferrer"
              class="text-right"
              *ngIf="userConfiguration$ | async as userConfiguration"
              target="_blank"
            >
              {{ 'turnkey_solution.summary.download_brochure' | translate }}
            </a>
          </mat-card-content>
        }
      </mat-card>
    </div>
  </div>
  <div class="summary-configuration">
    <mat-divider></mat-divider>
    <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
      {{ 'turnkey_solution.summary.configuration' | translate }}
    </h2>
    <a
      (click)="this.isExpanded ? closeAllPanels() : openAllPanels()"
      fxLayoutAlign="end center"
      data-test="toggle_expansion_panels"
    >
      {{
        (this.isExpanded
          ? 'turnkey_solution.common.collapse_all'
          : 'turnkey_solution.common.expand_all'
        ) | translate
      }}
    </a>

    <div
      fxLayout="row"
      fxLayoutAlign="flex-end"
      fxLayoutAlign.lt-md="flex-start"
      *ngIf="userConfiguration?.enable_descriptions"
    >
      <mat-slide-toggle
        data-test="toggle-more-details"
        (change)="toggleMoreDetails()"
        [checked]="true"
        >{{
          'turnkey_solution.summary.button_toggle_more_details' | translate
        }}</mat-slide-toggle
      >
    </div>
    <div class="summary-configuration__lists">
      <div
        *ngIf="(userConfiguration$ | async)?.data as data"
        fxLayout="column"
        fxLayoutGap="20"
      >
        <div *ngFor="let section of data">
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="isExpanded"
              data-cy="summary-accordion"
            >
              <mat-expansion-panel-header
                fxLayout.xs="column wrap"
                fxLayoutAlign="center center"
              >
                <mat-panel-title>
                  <h4>{{ section.name }}</h4>
                  <a
                    *ngIf="section.type === SectionType.Configuration"
                    class="h4 edit-link"
                    (click)="goToConfigurationSection($event, section.name)"
                  >
                    <mat-icon class="edit-link-icon">edit</mat-icon>
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-container
                *ngFor="let category of section?.sales_option_categories"
              >
                <div
                  class="summary-configuration__salesoption"
                  *ngFor="
                    let configuration_item of category?.configuration_items
                  "
                  fxLayout="row wrap"
                  fxLayout.lt-sm="column"
                  fxLayoutAlign="space-between baseline"
                  [fxLayoutGap]="0.2 | dsSpacing"
                >
                  <div
                    [fxFlex]="isSoMoreDetailsVisible ? 25 : 40"
                    fxLayoutAlign="flex-start center"
                  >
                    <span
                      class="cut-text-lt-md"
                      [matTooltip]="configuration_item.name"
                      [matTooltipDisabled]="
                        (configuration_item.name | translate).length < 17 ||
                        !media.isActive('lt-md')
                      "
                      >{{ configuration_item.name }}</span
                    >
                    <span
                      matTooltip="{{
                        'turnkey_solution.configuration.sales_option_tooltip_text'
                          | translate
                      }}"
                      [matTooltipPosition]="'above'"
                    >
                      <button
                        mat-icon-button
                        *ngIf="
                          configuration_item.more_details &&
                          configuration_item.more_details?.description !== ''
                        "
                        data-test="so_info_btn"
                        (click)="
                          openInfoDialog(configuration_item, section.name)
                        "
                      >
                        <mat-icon
                          matTooltip="{{
                            !configuration_item.description &&
                            !configuration_item.image_uri &&
                            !configuration_item.video_uri
                              ? ('turnkey_solution.common.no_additional_information_available'
                                | translate)
                              : ''
                          }}"
                          matTooltipPosition="right"
                          matTooltipClass="multiline-tooltip"
                          >info_outlined</mat-icon
                        >
                      </button>
                    </span>
                  </div>
                  <div
                    fxLayout="row wrap"
                    fxLayout.lt-sm="column"
                    [fxFlex]="isSoMoreDetailsVisible ? 25 : 60"
                    [fxLayoutAlign]="
                      isSoMoreDetailsVisible ? 'flex-start' : 'flex-end'
                    "
                    class="summary-configuration__salesoption--value"
                  >
                    <p>
                      <strong>{{ configuration_item.value }}</strong>
                    </p>

                    <ng-container
                      *ngIf="(isLoading$ | async) === false; else priceLoading"
                    >
                      <p
                        *ngIf="
                          userConfiguration.display_unit_prices &&
                          configuration_item.price
                        "
                        fxLayoutAlign.lt-sm="end"
                      >
                        &nbsp;{{
                          configuration_item.price.total_price
                            | currency: configuration_item.price.currency_code
                        }}
                      </p>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="
                      userConfiguration?.enable_descriptions &&
                      configuration_item.description &&
                      isSoMoreDetailsVisible
                    "
                    [fxFlex]="isSoMoreDetailsVisible ? 45 : 0"
                    class="summary-configuration__salesoption--description"
                  >
                    <span
                      *ngFor="let description of configuration_item.description"
                      [innerHtml]="description | slScDescriptionDecoration"
                    ></span>
                  </div>
                </div>
              </ng-container>
              <mat-divider></mat-divider>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div
    class="summary-details"
    *ngIf="userConfiguration$ | async as userConfiguration"
  >
    <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
      {{ 'turnkey_solution.summary.item_details' | translate }}
    </h2>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutAlign.lt-sm="center"
    >
      <ng-container *ngFor="let section of userConfiguration.data">
        <ng-container *ngFor="let category of section?.sales_option_categories">
          <ng-container
            *ngFor="let configuration_item of category?.configuration_items"
          >
            <mat-card
              *ngFor="let content of configuration_item.more_details?.content"
              class="summary-details__card"
            >
              <img
                mat-card-image
                *ngIf="content.media_type === MediaType.Image"
                src="{{ content.media_uri }}"
                alt="{{ content?.description }}"
              />
              <mat-card-content>
                <h5>
                  {{ configuration_item.name | uppercase }} -
                  <strong>{{ configuration_item.value }}</strong>
                </h5>
                <div
                  [innerHTML]="
                    configuration_item.more_details?.description
                      | slScDescriptionDecoration
                  "
                ></div>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="summary-sum">
    <mat-card
      *ngIf="userConfiguration$ | async as userConfiguration"
      class="summary-configuration__price"
    >
      <mat-card-content class="summary-configuration__price-wrap">
        <div
          class="summary-configuration__price-total-wrap"
          *ngIf="(isLoading$ | async) === false; else priceLoading"
        >
          <p>{{ 'turnkey_solution.summary.your_solution' | translate }}</p>
          <p>{{ 'turnkey_solution.summary.total_price' | translate }}</p>
          <h2 data-cy="model-name" [ngSwitch]="scProductLineName">
            <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TailLift">
              <span>{{ userConfiguration.selected_model }}</span>
            </ng-container>
            <ng-container
              *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane"
            >
              {{
                'turnkey_solution.common.model_name.timber_crane' | translate
              }}
            </ng-container>
            <ng-container
              *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber"
            >
              {{
                'turnkey_solution.common.model_name.short_timber' | translate
              }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ 'turnkey_solution.common.model_name.default' | translate }}
            </ng-container>
          </h2>
          <h2 data-cy="total-price">
            {{
              userConfiguration.total_price
                | currency: userConfiguration.currency_code
            }}
          </h2>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      *ngIf="
        (scProductLineName === urlFriendlyProductLineEnum.TimberCrane ||
          scProductLineName === urlFriendlyProductLineEnum.ShortTimber) &&
        modelDetails.length > 0
      "
      class="summary-configuration__price"
    >
      <mat-card-content class="summary-configuration__price-wrap">
        <div
          class="summary-configuration__price-weight-wrap"
          [ngClass]="{ single: modelDetails.length === 1 }"
          *ngIf="(isLoading$ | async) === false; else priceLoading"
        >
          <ng-container *ngFor="let details of modelDetails">
            <p>
              {{ details.weight_label }}
            </p>
            <p>
              {{ details.price_label }}
            </p>
          </ng-container>
          <ng-container *ngFor="let details of modelDetails">
            <h4>
              {{ details.weight?.formatted_weight }}
              {{ details.weight?.unit }}
            </h4>
            <h4>
              {{
                details.price?.total_price
                  | currency: details.price?.currency_code ?? ''
              }}
            </h4>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="summary-next">
    <mat-divider></mat-divider>
    <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
      {{ 'turnkey_solution.summary.next.main_title' | translate }}
    </h2>

    <div
      class="summary-next__container"
      fxLayout="row wrap"
      [fxLayoutGap.gt-md]="1 | dsSpacing"
      fxLayoutAlign.lt-lg="space-between"
      fxLayoutAlign.lt-sm="center"
    >
      <mat-card class="summary-next__card">
        <mat-card-content>
          <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
            <mat-icon class="summary-next__icon">share</mat-icon>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="summary-next__text--wrap"
          >
            <h3>{{ 'turnkey_solution.summary.button_share' | translate }}</h3>
            <p>{{ 'turnkey_solution.summary.next.share_text' | translate }}</p>
            <button
              mat-stroked-button
              type="button"
              [matMenuTriggerFor]="shareMenu"
              class="sc-summary-menu-btn full-width"
              data-test="sc-summary-menu"
            >
              {{ 'turnkey_solution.summary.button_share' | translate }}
            </button>
            <mat-menu #shareMenu="matMenu" [xPosition]="'before'">
              <button
                mat-menu-item
                disableRipple="true"
                (click)="openExportDialog()"
              >
                <mat-icon>download</mat-icon>
                {{ 'turnkey_solution.summary.button_export' | translate }}
              </button>
              <button mat-menu-item disableRipple="true" (click)="onCopy()">
                <mat-icon class="link-icon">link</mat-icon>
                {{ 'turnkey_solution.common.copy_link' | translate }}
              </button>
              <button
                mat-menu-item
                disableRipple="true"
                (click)="$event.preventDefault(); sendEmail()"
              >
                <mat-icon>email</mat-icon>
                {{ 'turnkey_solution.summary.email' | translate }}
              </button>
            </mat-menu>
          </div>
        </mat-card-content>
      </mat-card>
      <ng-container
        *ngIf="this.scProductLineName === urlFriendlyProductLineEnum.TailLift"
      >
        <mat-card class="summary-next__card">
          <mat-card-content>
            <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
              <mat-icon class="summary-next__icon">contact_mail</mat-icon>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="summary-next__text--wrap"
            >
              <h3>
                {{ 'turnkey_solution.summary.next.quote_title' | translate }}
              </h3>
              <p>
                {{ 'turnkey_solution.summary.next.quote_text' | translate }}
              </p>
              <button
                mat-stroked-button
                (click)="openContactFormDialog()"
                data-test="sc-summary-button"
                class="full-width"
              >
                {{ 'turnkey_solution.summary.button_contact_form' | translate }}
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <mat-card class="summary-next__card">
        <mat-card-content
          *ngIf="this.scProductLineName === urlFriendlyProductLineEnum.TailLift"
        >
          <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
            <mat-icon class="summary-next__icon">fact_check</mat-icon>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="summary-next__text--wrap"
          >
            <h3>
              {{
                'turnkey_solution.summary.feasibility_study_title' | translate
              }}
            </h3>
            <p>
              {{
                'turnkey_solution.summary.feasibility_study_text' | translate
              }}
            </p>
            <a
              href="https://tail-lift-study.palfinger.com/project"
              target="_blank"
              rel="noopener noreferrer"
              class="full-width"
              mat-stroked-button
            >
              {{
                'turnkey_solution.summary.feasibility_study_link' | translate
              }}
            </a>
          </div>
        </mat-card-content>
        <mat-card-content
          *ngIf="
            this.scProductLineName === urlFriendlyProductLineEnum.TimberCrane ||
            this.scProductLineName === urlFriendlyProductLineEnum.ShortTimber
          "
        >
          <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
            <mat-icon
              class="summary-next__icon"
              fontSet="pd"
              fontIcon="pd-documents"
            ></mat-icon>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="summary-next__text--wrap"
          >
            <h3>
              {{ 'turnkey_solution.summary.brochure_title' | translate }}
            </h3>
            <p>
              {{ 'turnkey_solution.summary.brochure_text' | translate }}
            </p>
            <a
              [href]="userConfiguration?.brochure_uri"
              target="_blank"
              rel="noopener noreferrer"
              class="full-width"
              mat-stroked-button
            >
              {{ 'turnkey_solution.summary.download_brochure' | translate }}
            </a>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="summary-next__card">
        <mat-card-content>
          <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
            <mat-icon class="summary-next__icon">info</mat-icon>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="summary-next__text--wrap"
          >
            <h3>
              {{
                'turnkey_solution.summary.next.product_details_title'
                  | translate
              }}
            </h3>
            <p>
              {{
                'turnkey_solution.summary.next.product_details_text' | translate
              }}
            </p>
            <a
              [href]="userConfiguration.external_uri"
              target="_blank"
              rel="noopener noreferrer"
              class="full-width"
              mat-stroked-button
              *ngIf="userConfiguration$ | async as userConfiguration"
            >
              {{ 'turnkey_solution.common.learn_more' | translate }}
            </a>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <sl-shared-sc-footer
    *ngIf="scSelectedLanguage"
    [language]="scSelectedLanguage"
  ></sl-shared-sc-footer>

  <ng-template #priceLoading>
    <ds-loading-placeholder
      [width]="10 | dsSpacing"
      [height]="2 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>

  <ng-template #descriptionLoading>
    <ds-loading-placeholder
      [width]="10 | dsSpacing"
      [height]="8 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>

  <ng-template #dropdownLoading>
    <ds-loading-placeholder
      [width]="7 | dsSpacing"
      [height]="2 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>
</div>
