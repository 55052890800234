import { Action, createReducer, on } from '@ngrx/store';
import { SlScConfigurationActions } from './configuration.actions';
import { SlScConfigurationState, initialState } from './configuration.store';

export const slScConfigurationReducer = createReducer(
  initialState,
  on(SlScConfigurationActions.toggleDebugView, (state) => ({
    ...state,
    showDebugView: !state.showDebugView,
  })),
  on(SlScConfigurationActions.resetToggleDebugView, (state) => ({
    ...state,
    showDebugView: false,
  })),
  on(
    SlScConfigurationActions.saveSelectedSalesOption,
    (
      state,
      {
        guideId,
        userGuid,
        language,
        selectedSalesOptions,
        selectedSalesOptionName,
      },
    ) => ({
      ...state,
      guideId: guideId,
      userGuid: userGuid,
      language: language,
      selectedSalesOptions: selectedSalesOptions,
      selectedSalesOptionName: selectedSalesOptionName,
      isGroupLoading: true,
    }),
  ),
  on(
    SlScConfigurationActions.saveSelectedSalesOptionSuccess,
    (state, { guide }) => ({
      ...state,
      guide: guide,
      isGroupLoading: false,
    }),
  ),
  on(
    SlScConfigurationActions.saveSelectedSalesOptionError,
    (state, { payload }) => ({
      ...state,
      savingSelectedSalesOptionError: payload,
      isGroupLoading: false,
    }),
  ),
  on(
    SlScConfigurationActions.saveDeselectedSalesOption,
    (state, { guideId, userGuid, language, deselectedSalesOption }) => ({
      ...state,
      guideId: guideId,
      userGuid: userGuid,
      language: language,
      deselectedSalesOption: deselectedSalesOption,
      isGroupLoading: true,
    }),
  ),
  on(
    SlScConfigurationActions.saveDeselectedSalesOptionSuccess,
    // eslint-disable-next-line sonarjs/no-identical-functions
    (state, { guide }) => ({
      ...state,
      guide: guide,
      isGroupLoading: false,
    }),
  ),
  on(
    SlScConfigurationActions.saveDeselectedSalesOptionError,
    (state, { payload }) => ({
      ...state,
      savingDeselectedSalesOptionError: payload,
      isGroupLoading: false,
    }),
  ),
  on(
    SlScConfigurationActions.saveSelectedAttributes,
    (state, { guideId, userGuid, language, selectedAttributeValue }) => ({
      ...state,
      guideId: guideId,
      userGuid: userGuid,
      language: language,
      selectedAttributeValue: selectedAttributeValue,
      isAttributeSubmitting: true,
    }),
  ),
  on(
    SlScConfigurationActions.saveSelectedAttributesSuccess,
    (state, { guide }) => ({
      ...state,
      guide: guide,
      isAttributeSubmitting: false,
    }),
  ),
  on(
    SlScConfigurationActions.saveSelectedAttributesError,
    (state, { payload }) => ({
      ...state,
      savingSelectedSalesOptionError: payload,
    }),
  ),
  on(
    SlScConfigurationActions.saveSliderValues,
    (state, { selectedSliderValues }) => ({
      ...state,
      sliderValues: selectedSliderValues,
    }),
  ),
  on(SlScConfigurationActions.resetSliderValues, () => ({
    ...initialState,
  })),
  on(
    SlScConfigurationActions.saveSelectedColor,
    (state, { activeColor, coloredPart }) => ({
      ...state,
      activeColor: activeColor,
      coloredPart: coloredPart,
    }),
  ),
  on(SlScConfigurationActions.saveModelImage, (state, { modelImage }) => ({
    ...state,
    modelImage: modelImage,
  })),
);

export function reducer(
  state: SlScConfigurationState | undefined,
  action: Action,
) {
  return slScConfigurationReducer(state, action);
}
