<div class="sl-sc-category-list-wrap">
  <div
    class="sl-sc-sticky-subheader"
    [ngClass]="displayConfigurationDebuggingToggle ? 'height-with-toggle' : ''"
    fxLayout="column wrap"
    fxLayoutAlign="center flex-end"
    fxLayoutAlign.lt-md="flex-start"
  >
    <p>* {{ 'turnkey_solution.common.mandatory' | translate }}</p>
  </div>

  <mat-accordion
    [multi]="true"
    #accordion="matAccordion"
    *ngFor="
      let category of salesOptionCategories?.data;
      trackBy: trackByCategoryFn
    "
  >
    <h3>{{ category?.name }}</h3>
    <div
      *ngFor="
        let group of category?.sales_option_groups;
        trackBy: trackByGroupFn
      "
    >
      <sl-sc-sales-option-group
        [salesOptionGroup]="group"
        [userGuid]="userGuid"
        [guide]="guide"
        [sectionName]="sectionName"
      ></sl-sc-sales-option-group>
    </div>
  </mat-accordion>
</div>
