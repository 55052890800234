import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  DsSnackbar,
  DsSnackbarModule,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DsLoadingModule } from '@design-system/components/loading';
import { Currency, SummaryService } from '@sales-libs/sc-summary/data-access';
import { Languages } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import { Analytics, SlScCurrencySymbolPipe } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { SlScSummaryActions, SlScSummarySelectors } from '../store';

export const imports = [
  CommonModule,
  TranslateModule,
  MatButtonModule,
  MatDialogModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  GoogleAnalytics4DirectiveModule,
  DsLoadingModule,
  DsSnackbarModule,
  SlScCurrencySymbolPipe,
];

@Component({
  selector: 'sl-sc-export-dialog',
  standalone: true,
  imports: imports,
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class SlScExportDialogComponent implements OnInit {
  pdfDownloading = false;
  userGuid: string;
  guideId: number;
  selectedLanguage: string;
  selectedCurrency: string;
  shareableLink: string;
  supportedLanguages$: Observable<Languages>;
  supportedCurrencies$: Observable<Currency[]>;
  configurationCode: string;
  displayDescriptions: boolean;

  readonly Analytics = Analytics;

  constructor(
    public dialogRef: MatDialogRef<SlScExportDialogComponent>,
    private summaryService: SummaryService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      guid: string;
      guideId: number;
      configurationCode: string;
      currency: string;
      enableSoDescriptions: boolean;
      scSelectedLanguage: string;
      scUrl?: string;
    },
    private store: Store,
    private _snackbar: DsSnackbar,
    private translateService: TranslateService,
  ) {
    this.userGuid = data.guid;
    this.guideId = data.guideId;
    this.configurationCode = data.configurationCode;
    this.shareableLink = data.scUrl + `/share/` + this.configurationCode;
    this.selectedCurrency = data.currency || 'EUR';
    this.displayDescriptions = data.enableSoDescriptions;
    this.selectedLanguage = data.scSelectedLanguage.toUpperCase();
  }

  ngOnInit() {
    this.getLanguages();
    this.getCurrencies();
  }

  getLanguages(): void {
    this.store.dispatch(
      ScSharedActions.getLanguages({ guideId: this.guideId }),
    );
    this.supportedLanguages$ = this.store.pipe(
      select(ScSharedSelectors.languages),
      filterTruthy(),
    );
  }

  getCurrencies(): void {
    this.store.dispatch(
      SlScSummaryActions.getCurrencies({ guideId: this.guideId }),
    );
    this.supportedCurrencies$ = this.store.pipe(
      select(SlScSummarySelectors.currencies),
      filterTruthy(),
    );
  }

  changeSelectedLanguage(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;
  }

  changeSelectedCurrency(selectedCurrency: string) {
    this.selectedCurrency = selectedCurrency;
  }

  downloadPDF(): any {
    this.pdfDownloading = true;
    this.summaryService
      .getSummary(
        this.guideId,
        this.userGuid,
        this.selectedLanguage,
        this.selectedCurrency,
        this.shareableLink,
        this.displayDescriptions,
      )
      .subscribe((x) => {
        this.summaryDownload(x);
        this.pdfDownloading = false;
        this.closeDialog();
        this.showSuccessMessage();
      });
  }

  summaryDownload(x: Blob): void {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([x], { type: 'application/pdf' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = `PALFINGER Print ${this.configurationCode}`;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  }

  showSuccessMessage(): void {
    this._snackbar.queue(
      this.translateService.instant(
        'turnkey_solution.summary.download_success',
      ),
      {
        type: DsSnackbarType.Success,
      },
    );
  }

  closeDialog(): void {
    this.dialogRef?.close();
  }
}
