/* eslint-disable babylonjs/available */
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as WebGPUConstants from "./webgpuConstants.js";
import { Scalar } from "../../Maths/math.scalar.js";

/** @internal */
export class WebGPUTextureHelper {
  static ComputeNumMipmapLevels(width, height) {
    return Scalar.ILog2(Math.max(width, height)) + 1;
  }
  static GetTextureTypeFromFormat(format) {
    switch (format) {
      // One Component = 8 bits
      case WebGPUConstants.TextureFormat.R8Unorm:
      case WebGPUConstants.TextureFormat.R8Snorm:
      case WebGPUConstants.TextureFormat.R8Uint:
      case WebGPUConstants.TextureFormat.R8Sint:
      case WebGPUConstants.TextureFormat.RG8Unorm:
      case WebGPUConstants.TextureFormat.RG8Snorm:
      case WebGPUConstants.TextureFormat.RG8Uint:
      case WebGPUConstants.TextureFormat.RG8Sint:
      case WebGPUConstants.TextureFormat.RGBA8Unorm:
      case WebGPUConstants.TextureFormat.RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGBA8Snorm:
      case WebGPUConstants.TextureFormat.RGBA8Uint:
      case WebGPUConstants.TextureFormat.RGBA8Sint:
      case WebGPUConstants.TextureFormat.BGRA8Unorm:
      case WebGPUConstants.TextureFormat.BGRA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGB10A2UINT: // composite format - let's say it's byte...
      case WebGPUConstants.TextureFormat.RGB10A2Unorm: // composite format - let's say it's byte...
      case WebGPUConstants.TextureFormat.RGB9E5UFloat: // composite format - let's say it's byte...
      case WebGPUConstants.TextureFormat.RG11B10UFloat: // composite format - let's say it's byte...
      case WebGPUConstants.TextureFormat.BC7RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC7RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC6HRGBUFloat:
      case WebGPUConstants.TextureFormat.BC6HRGBFloat:
      case WebGPUConstants.TextureFormat.BC5RGUnorm:
      case WebGPUConstants.TextureFormat.BC5RGSnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC2RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC2RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC4RUnorm:
      case WebGPUConstants.TextureFormat.BC4RSnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGB8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGBA8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.EACR11Unorm:
      case WebGPUConstants.TextureFormat.EACR11Snorm:
      case WebGPUConstants.TextureFormat.EACRG11Unorm:
      case WebGPUConstants.TextureFormat.EACRG11Snorm:
      case WebGPUConstants.TextureFormat.ASTC4x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC4x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x12Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x12UnormSRGB:
      case WebGPUConstants.TextureFormat.Stencil8:
        return 0;
      // One component = 16 bits
      case WebGPUConstants.TextureFormat.R16Uint:
      case WebGPUConstants.TextureFormat.R16Sint:
      case WebGPUConstants.TextureFormat.RG16Uint:
      case WebGPUConstants.TextureFormat.RG16Sint:
      case WebGPUConstants.TextureFormat.RGBA16Uint:
      case WebGPUConstants.TextureFormat.RGBA16Sint:
      case WebGPUConstants.TextureFormat.Depth16Unorm:
        return 5;
      case WebGPUConstants.TextureFormat.R16Float:
      case WebGPUConstants.TextureFormat.RG16Float:
      case WebGPUConstants.TextureFormat.RGBA16Float:
        return 2;
      // One component = 32 bits
      case WebGPUConstants.TextureFormat.R32Uint:
      case WebGPUConstants.TextureFormat.R32Sint:
      case WebGPUConstants.TextureFormat.RG32Uint:
      case WebGPUConstants.TextureFormat.RG32Sint:
      case WebGPUConstants.TextureFormat.RGBA32Uint:
      case WebGPUConstants.TextureFormat.RGBA32Sint:
        return 7;
      case WebGPUConstants.TextureFormat.R32Float:
      case WebGPUConstants.TextureFormat.RG32Float:
      case WebGPUConstants.TextureFormat.RGBA32Float:
      case WebGPUConstants.TextureFormat.Depth32Float:
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
      case WebGPUConstants.TextureFormat.Depth24Plus:
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
        return 1;
    }
    return 0;
  }
  static GetBlockInformationFromFormat(format) {
    switch (format) {
      // 8 bits formats
      case WebGPUConstants.TextureFormat.R8Unorm:
      case WebGPUConstants.TextureFormat.R8Snorm:
      case WebGPUConstants.TextureFormat.R8Uint:
      case WebGPUConstants.TextureFormat.R8Sint:
        return {
          width: 1,
          height: 1,
          length: 1
        };
      // 16 bits formats
      case WebGPUConstants.TextureFormat.R16Uint:
      case WebGPUConstants.TextureFormat.R16Sint:
      case WebGPUConstants.TextureFormat.R16Float:
      case WebGPUConstants.TextureFormat.RG8Unorm:
      case WebGPUConstants.TextureFormat.RG8Snorm:
      case WebGPUConstants.TextureFormat.RG8Uint:
      case WebGPUConstants.TextureFormat.RG8Sint:
        return {
          width: 1,
          height: 1,
          length: 2
        };
      // 32 bits formats
      case WebGPUConstants.TextureFormat.R32Uint:
      case WebGPUConstants.TextureFormat.R32Sint:
      case WebGPUConstants.TextureFormat.R32Float:
      case WebGPUConstants.TextureFormat.RG16Uint:
      case WebGPUConstants.TextureFormat.RG16Sint:
      case WebGPUConstants.TextureFormat.RG16Float:
      case WebGPUConstants.TextureFormat.RGBA8Unorm:
      case WebGPUConstants.TextureFormat.RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGBA8Snorm:
      case WebGPUConstants.TextureFormat.RGBA8Uint:
      case WebGPUConstants.TextureFormat.RGBA8Sint:
      case WebGPUConstants.TextureFormat.BGRA8Unorm:
      case WebGPUConstants.TextureFormat.BGRA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGB9E5UFloat:
      case WebGPUConstants.TextureFormat.RGB10A2UINT:
      case WebGPUConstants.TextureFormat.RGB10A2Unorm:
      case WebGPUConstants.TextureFormat.RG11B10UFloat:
        return {
          width: 1,
          height: 1,
          length: 4
        };
      // 64 bits formats
      case WebGPUConstants.TextureFormat.RG32Uint:
      case WebGPUConstants.TextureFormat.RG32Sint:
      case WebGPUConstants.TextureFormat.RG32Float:
      case WebGPUConstants.TextureFormat.RGBA16Uint:
      case WebGPUConstants.TextureFormat.RGBA16Sint:
      case WebGPUConstants.TextureFormat.RGBA16Float:
        return {
          width: 1,
          height: 1,
          length: 8
        };
      // 128 bits formats
      case WebGPUConstants.TextureFormat.RGBA32Uint:
      case WebGPUConstants.TextureFormat.RGBA32Sint:
      case WebGPUConstants.TextureFormat.RGBA32Float:
        return {
          width: 1,
          height: 1,
          length: 16
        };
      // Depth and stencil formats
      case WebGPUConstants.TextureFormat.Stencil8:
        // eslint-disable-next-line no-throw-literal
        throw "No fixed size for Stencil8 format!";
      case WebGPUConstants.TextureFormat.Depth16Unorm:
        return {
          width: 1,
          height: 1,
          length: 2
        };
      case WebGPUConstants.TextureFormat.Depth24Plus:
        // eslint-disable-next-line no-throw-literal
        throw "No fixed size for Depth24Plus format!";
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
        // eslint-disable-next-line no-throw-literal
        throw "No fixed size for Depth24PlusStencil8 format!";
      case WebGPUConstants.TextureFormat.Depth32Float:
        return {
          width: 1,
          height: 1,
          length: 4
        };
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
        return {
          width: 1,
          height: 1,
          length: 5
        };
      // BC compressed formats usable if "texture-compression-bc" is both
      // supported by the device/user agent and enabled in requestDevice.
      case WebGPUConstants.TextureFormat.BC7RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC7RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC6HRGBUFloat:
      case WebGPUConstants.TextureFormat.BC6HRGBFloat:
      case WebGPUConstants.TextureFormat.BC5RGUnorm:
      case WebGPUConstants.TextureFormat.BC5RGSnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC2RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC2RGBAUnormSRGB:
        return {
          width: 4,
          height: 4,
          length: 16
        };
      case WebGPUConstants.TextureFormat.BC4RUnorm:
      case WebGPUConstants.TextureFormat.BC4RSnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnormSRGB:
        return {
          width: 4,
          height: 4,
          length: 8
        };
      // ETC2 compressed formats usable if "texture-compression-etc2" is both
      // supported by the device/user agent and enabled in requestDevice.
      case WebGPUConstants.TextureFormat.ETC2RGB8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1UnormSRGB:
      case WebGPUConstants.TextureFormat.EACR11Unorm:
      case WebGPUConstants.TextureFormat.EACR11Snorm:
        return {
          width: 4,
          height: 4,
          length: 8
        };
      case WebGPUConstants.TextureFormat.ETC2RGBA8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.EACRG11Unorm:
      case WebGPUConstants.TextureFormat.EACRG11Snorm:
        return {
          width: 4,
          height: 4,
          length: 16
        };
      // ASTC compressed formats usable if "texture-compression-astc" is both
      // supported by the device/user agent and enabled in requestDevice.
      case WebGPUConstants.TextureFormat.ASTC4x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC4x4UnormSRGB:
        return {
          width: 4,
          height: 4,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC5x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x4UnormSRGB:
        return {
          width: 5,
          height: 4,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC5x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x5UnormSRGB:
        return {
          width: 5,
          height: 5,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC6x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x5UnormSRGB:
        return {
          width: 6,
          height: 5,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC6x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x6UnormSRGB:
        return {
          width: 6,
          height: 6,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC8x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x5UnormSRGB:
        return {
          width: 8,
          height: 5,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC8x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x6UnormSRGB:
        return {
          width: 8,
          height: 6,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC8x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x8UnormSRGB:
        return {
          width: 8,
          height: 8,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC10x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x5UnormSRGB:
        return {
          width: 10,
          height: 5,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC10x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x6UnormSRGB:
        return {
          width: 10,
          height: 6,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC10x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x8UnormSRGB:
        return {
          width: 10,
          height: 8,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC10x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x10UnormSRGB:
        return {
          width: 10,
          height: 10,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC12x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x10UnormSRGB:
        return {
          width: 12,
          height: 10,
          length: 16
        };
      case WebGPUConstants.TextureFormat.ASTC12x12Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x12UnormSRGB:
        return {
          width: 12,
          height: 12,
          length: 16
        };
    }
    return {
      width: 1,
      height: 1,
      length: 4
    };
  }
  static IsHardwareTexture(texture) {
    return !!texture.release;
  }
  static IsInternalTexture(texture) {
    return !!texture.dispose;
  }
  static IsImageBitmap(imageBitmap) {
    return imageBitmap.close !== undefined;
  }
  static IsImageBitmapArray(imageBitmap) {
    return Array.isArray(imageBitmap) && imageBitmap[0].close !== undefined;
  }
  static IsCompressedFormat(format) {
    switch (format) {
      case WebGPUConstants.TextureFormat.BC7RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC7RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC6HRGBFloat:
      case WebGPUConstants.TextureFormat.BC6HRGBUFloat:
      case WebGPUConstants.TextureFormat.BC5RGSnorm:
      case WebGPUConstants.TextureFormat.BC5RGUnorm:
      case WebGPUConstants.TextureFormat.BC4RSnorm:
      case WebGPUConstants.TextureFormat.BC4RUnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC3RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC2RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC2RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC1RGBAUnorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGBA8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.EACR11Unorm:
      case WebGPUConstants.TextureFormat.EACR11Snorm:
      case WebGPUConstants.TextureFormat.EACRG11Unorm:
      case WebGPUConstants.TextureFormat.EACRG11Snorm:
      case WebGPUConstants.TextureFormat.ASTC4x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC4x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x12Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x12UnormSRGB:
        return true;
    }
    return false;
  }
  static GetWebGPUTextureFormat(type, format, useSRGBBuffer = false) {
    switch (format) {
      case 15:
        return WebGPUConstants.TextureFormat.Depth16Unorm;
      case 16:
        return WebGPUConstants.TextureFormat.Depth24Plus;
      case 13:
        return WebGPUConstants.TextureFormat.Depth24PlusStencil8;
      case 14:
        return WebGPUConstants.TextureFormat.Depth32Float;
      case 18:
        return WebGPUConstants.TextureFormat.Depth32FloatStencil8;
      case 19:
        return WebGPUConstants.TextureFormat.Stencil8;
      case 36492:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.BC7RGBAUnormSRGB : WebGPUConstants.TextureFormat.BC7RGBAUnorm;
      case 36495:
        return WebGPUConstants.TextureFormat.BC6HRGBUFloat;
      case 36494:
        return WebGPUConstants.TextureFormat.BC6HRGBFloat;
      case 33779:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.BC3RGBAUnormSRGB : WebGPUConstants.TextureFormat.BC3RGBAUnorm;
      case 33778:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.BC2RGBAUnormSRGB : WebGPUConstants.TextureFormat.BC2RGBAUnorm;
      case 33777:
      case 33776:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.BC1RGBAUnormSRGB : WebGPUConstants.TextureFormat.BC1RGBAUnorm;
      case 37808:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.ASTC4x4UnormSRGB : WebGPUConstants.TextureFormat.ASTC4x4Unorm;
      case 36196:
      case 37492:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.ETC2RGB8UnormSRGB : WebGPUConstants.TextureFormat.ETC2RGB8Unorm;
      case 37496:
        return useSRGBBuffer ? WebGPUConstants.TextureFormat.ETC2RGBA8UnormSRGB : WebGPUConstants.TextureFormat.ETC2RGBA8Unorm;
    }
    switch (type) {
      case 3:
        switch (format) {
          case 6:
            return WebGPUConstants.TextureFormat.R8Snorm;
          case 7:
            return WebGPUConstants.TextureFormat.RG8Snorm;
          case 4:
            // eslint-disable-next-line no-throw-literal
            throw "RGB format not supported in WebGPU";
          case 8:
            return WebGPUConstants.TextureFormat.R8Sint;
          case 9:
            return WebGPUConstants.TextureFormat.RG8Sint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA8Sint;
          default:
            return WebGPUConstants.TextureFormat.RGBA8Snorm;
        }
      case 0:
        switch (format) {
          case 6:
            return WebGPUConstants.TextureFormat.R8Unorm;
          case 7:
            return WebGPUConstants.TextureFormat.RG8Unorm;
          case 4:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB format not supported in WebGPU";
          case 5:
            return useSRGBBuffer ? WebGPUConstants.TextureFormat.RGBA8UnormSRGB : WebGPUConstants.TextureFormat.RGBA8Unorm;
          case 12:
            return useSRGBBuffer ? WebGPUConstants.TextureFormat.BGRA8UnormSRGB : WebGPUConstants.TextureFormat.BGRA8Unorm;
          case 8:
            return WebGPUConstants.TextureFormat.R8Uint;
          case 9:
            return WebGPUConstants.TextureFormat.RG8Uint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA8Uint;
          case 0:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_ALPHA format not supported in WebGPU";
          case 1:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_LUMINANCE format not supported in WebGPU";
          case 2:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_LUMINANCE_ALPHA format not supported in WebGPU";
          default:
            return WebGPUConstants.TextureFormat.RGBA8Unorm;
        }
      case 4:
        switch (format) {
          case 8:
            return WebGPUConstants.TextureFormat.R16Sint;
          case 9:
            return WebGPUConstants.TextureFormat.RG16Sint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA16Sint;
          default:
            return WebGPUConstants.TextureFormat.RGBA16Sint;
        }
      case 5:
        switch (format) {
          case 8:
            return WebGPUConstants.TextureFormat.R16Uint;
          case 9:
            return WebGPUConstants.TextureFormat.RG16Uint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA16Uint;
          default:
            return WebGPUConstants.TextureFormat.RGBA16Uint;
        }
      case 6:
        switch (format) {
          case 8:
            return WebGPUConstants.TextureFormat.R32Sint;
          case 9:
            return WebGPUConstants.TextureFormat.RG32Sint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA32Sint;
          default:
            return WebGPUConstants.TextureFormat.RGBA32Sint;
        }
      case 7:
        // Refers to UNSIGNED_INT
        switch (format) {
          case 8:
            return WebGPUConstants.TextureFormat.R32Uint;
          case 9:
            return WebGPUConstants.TextureFormat.RG32Uint;
          case 10:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB_INTEGER format not supported in WebGPU";
          case 11:
            return WebGPUConstants.TextureFormat.RGBA32Uint;
          default:
            return WebGPUConstants.TextureFormat.RGBA32Uint;
        }
      case 1:
        switch (format) {
          case 6:
            return WebGPUConstants.TextureFormat.R32Float;
          // By default. Other possibility is R16Float.
          case 7:
            return WebGPUConstants.TextureFormat.RG32Float;
          // By default. Other possibility is RG16Float.
          case 4:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB format not supported in WebGPU";
          case 5:
            return WebGPUConstants.TextureFormat.RGBA32Float;
          // By default. Other possibility is RGBA16Float.
          default:
            return WebGPUConstants.TextureFormat.RGBA32Float;
        }
      case 2:
        switch (format) {
          case 6:
            return WebGPUConstants.TextureFormat.R16Float;
          case 7:
            return WebGPUConstants.TextureFormat.RG16Float;
          case 4:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGB format not supported in WebGPU";
          case 5:
            return WebGPUConstants.TextureFormat.RGBA16Float;
          default:
            return WebGPUConstants.TextureFormat.RGBA16Float;
        }
      case 10:
        // eslint-disable-next-line no-throw-literal
        throw "TEXTURETYPE_UNSIGNED_SHORT_5_6_5 format not supported in WebGPU";
      case 13:
        switch (format) {
          case 5:
            return WebGPUConstants.TextureFormat.RG11B10UFloat;
          case 11:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGBA_INTEGER format not supported in WebGPU when type is TEXTURETYPE_UNSIGNED_INT_10F_11F_11F_REV";
          default:
            return WebGPUConstants.TextureFormat.RG11B10UFloat;
        }
      case 14:
        switch (format) {
          case 5:
            return WebGPUConstants.TextureFormat.RGB9E5UFloat;
          case 11:
            // eslint-disable-next-line no-throw-literal
            throw "TEXTUREFORMAT_RGBA_INTEGER format not supported in WebGPU when type is TEXTURETYPE_UNSIGNED_INT_5_9_9_9_REV";
          default:
            return WebGPUConstants.TextureFormat.RGB9E5UFloat;
        }
      case 8:
        // eslint-disable-next-line no-throw-literal
        throw "TEXTURETYPE_UNSIGNED_SHORT_4_4_4_4 format not supported in WebGPU";
      case 9:
        // eslint-disable-next-line no-throw-literal
        throw "TEXTURETYPE_UNSIGNED_SHORT_5_5_5_1 format not supported in WebGPU";
      case 11:
        switch (format) {
          case 5:
            return WebGPUConstants.TextureFormat.RGB10A2Unorm;
          case 11:
            return WebGPUConstants.TextureFormat.RGB10A2UINT;
          default:
            return WebGPUConstants.TextureFormat.RGB10A2Unorm;
        }
    }
    return useSRGBBuffer ? WebGPUConstants.TextureFormat.RGBA8UnormSRGB : WebGPUConstants.TextureFormat.RGBA8Unorm;
  }
  static GetNumChannelsFromWebGPUTextureFormat(format) {
    switch (format) {
      case WebGPUConstants.TextureFormat.R8Unorm:
      case WebGPUConstants.TextureFormat.R8Snorm:
      case WebGPUConstants.TextureFormat.R8Uint:
      case WebGPUConstants.TextureFormat.R8Sint:
      case WebGPUConstants.TextureFormat.BC4RUnorm:
      case WebGPUConstants.TextureFormat.BC4RSnorm:
      case WebGPUConstants.TextureFormat.R16Uint:
      case WebGPUConstants.TextureFormat.R16Sint:
      case WebGPUConstants.TextureFormat.Depth16Unorm:
      case WebGPUConstants.TextureFormat.R16Float:
      case WebGPUConstants.TextureFormat.R32Uint:
      case WebGPUConstants.TextureFormat.R32Sint:
      case WebGPUConstants.TextureFormat.R32Float:
      case WebGPUConstants.TextureFormat.Depth32Float:
      case WebGPUConstants.TextureFormat.Stencil8:
      case WebGPUConstants.TextureFormat.Depth24Plus:
      case WebGPUConstants.TextureFormat.EACR11Unorm:
      case WebGPUConstants.TextureFormat.EACR11Snorm:
        return 1;
      case WebGPUConstants.TextureFormat.RG8Unorm:
      case WebGPUConstants.TextureFormat.RG8Snorm:
      case WebGPUConstants.TextureFormat.RG8Uint:
      case WebGPUConstants.TextureFormat.RG8Sint:
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
      case WebGPUConstants.TextureFormat.BC5RGUnorm:
      case WebGPUConstants.TextureFormat.BC5RGSnorm:
      case WebGPUConstants.TextureFormat.RG16Uint:
      case WebGPUConstants.TextureFormat.RG16Sint:
      case WebGPUConstants.TextureFormat.RG16Float:
      case WebGPUConstants.TextureFormat.RG32Uint:
      case WebGPUConstants.TextureFormat.RG32Sint:
      case WebGPUConstants.TextureFormat.RG32Float:
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
      case WebGPUConstants.TextureFormat.EACRG11Unorm:
      case WebGPUConstants.TextureFormat.EACRG11Snorm:
        return 2;
      case WebGPUConstants.TextureFormat.RGB9E5UFloat:
      case WebGPUConstants.TextureFormat.RG11B10UFloat:
      case WebGPUConstants.TextureFormat.BC6HRGBUFloat:
      case WebGPUConstants.TextureFormat.BC6HRGBFloat:
      case WebGPUConstants.TextureFormat.ETC2RGB8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8UnormSRGB:
        return 3;
      case WebGPUConstants.TextureFormat.RGBA8Unorm:
      case WebGPUConstants.TextureFormat.RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGBA8Snorm:
      case WebGPUConstants.TextureFormat.RGBA8Uint:
      case WebGPUConstants.TextureFormat.RGBA8Sint:
      case WebGPUConstants.TextureFormat.BGRA8Unorm:
      case WebGPUConstants.TextureFormat.BGRA8UnormSRGB:
      case WebGPUConstants.TextureFormat.RGB10A2UINT:
      case WebGPUConstants.TextureFormat.RGB10A2Unorm:
      case WebGPUConstants.TextureFormat.BC7RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC7RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC3RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC3RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC2RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC2RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.BC1RGBAUnorm:
      case WebGPUConstants.TextureFormat.BC1RGBAUnormSRGB:
      case WebGPUConstants.TextureFormat.RGBA16Uint:
      case WebGPUConstants.TextureFormat.RGBA16Sint:
      case WebGPUConstants.TextureFormat.RGBA16Float:
      case WebGPUConstants.TextureFormat.RGBA32Uint:
      case WebGPUConstants.TextureFormat.RGBA32Sint:
      case WebGPUConstants.TextureFormat.RGBA32Float:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGB8A1UnormSRGB:
      case WebGPUConstants.TextureFormat.ETC2RGBA8Unorm:
      case WebGPUConstants.TextureFormat.ETC2RGBA8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC4x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC4x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x4Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x4UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC5x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC5x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC6x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC6x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC8x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC8x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x5Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x5UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x6Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x6UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x8Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x8UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC10x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC10x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x10Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x10UnormSRGB:
      case WebGPUConstants.TextureFormat.ASTC12x12Unorm:
      case WebGPUConstants.TextureFormat.ASTC12x12UnormSRGB:
        return 4;
    }
    // eslint-disable-next-line no-throw-literal
    throw `Unknown format ${format}!`;
  }
  static HasStencilAspect(format) {
    switch (format) {
      case WebGPUConstants.TextureFormat.Stencil8:
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
        return true;
    }
    return false;
  }
  static HasDepthAndStencilAspects(format) {
    switch (format) {
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
        return true;
    }
    return false;
  }
  static GetDepthFormatOnly(format) {
    switch (format) {
      case WebGPUConstants.TextureFormat.Depth16Unorm:
        return WebGPUConstants.TextureFormat.Depth16Unorm;
      case WebGPUConstants.TextureFormat.Depth24Plus:
        return WebGPUConstants.TextureFormat.Depth24Plus;
      case WebGPUConstants.TextureFormat.Depth24PlusStencil8:
        return WebGPUConstants.TextureFormat.Depth24Plus;
      case WebGPUConstants.TextureFormat.Depth32Float:
        return WebGPUConstants.TextureFormat.Depth32Float;
      case WebGPUConstants.TextureFormat.Depth32FloatStencil8:
        return WebGPUConstants.TextureFormat.Depth32Float;
    }
    return format;
  }
  static GetSample(sampleCount) {
    // WebGPU only supports 1 or 4
    return sampleCount > 1 ? 4 : 1;
  }
}
