export interface SlScConfigurationState {
  isGroupLoading: boolean;
  showDebugView: boolean;
  sliderValues?: { [name: string]: number | undefined };
  activeColor?: string;
  coloredPart?: string;
  isAttributeSubmitting: boolean;
  selectedSalesOptionName?: string | null | undefined;
  modelImage?: string;
}

export const initialState: SlScConfigurationState = {
  isGroupLoading: false,
  showDebugView: false,
  sliderValues: { height: 0, width: 0 },
  activeColor: undefined,
  coloredPart: undefined,
  isAttributeSubmitting: false,
  selectedSalesOptionName: undefined,
  modelImage: undefined,
};
