import { createAction, props } from '@ngrx/store';
import {
  Guide,
  ProblemDetails,
  SelectedAttributeValue,
  SelectedSalesOptions,
} from '@sales-libs/sc-configuration/data-access';

export namespace SlScConfigurationActions {
  export const toggleDebugView = createAction(
    '[Configuration] TOGGLE_DEBUG_VIEW',
  );
  export const resetToggleDebugView = createAction(
    '[Configuration] RESET_TOGGLE_DEBUG_VIEW',
  );

  export const saveSelectedSalesOption = createAction(
    '[Configuration] SAVE_SELECTED_SALES_OPTION',
    props<{
      guideId: number;
      userGuid: string;
      language: string;
      selectedSalesOptions: SelectedSalesOptions;
      selectedSalesOptionName: string | null | undefined;
    }>(),
  );

  export const saveSelectedSalesOptionSuccess = createAction(
    '[Configuration] SAVE_SELECTED_SALES_OPTION_SUCCESS',
    props<{ guide: Guide }>(),
  );

  export const saveSelectedSalesOptionError = createAction(
    '[Configuration] SAVE_SELECTED_SALES_OPTION_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const saveDeselectedSalesOption = createAction(
    '[Configuration] SAVE_DESELECTED_SALES_OPTION',
    props<{
      guideId: number;
      userGuid: string;
      language: string;
      deselectedSalesOption: number;
    }>(),
  );

  export const saveDeselectedSalesOptionSuccess = createAction(
    '[Configuration] SAVE_DESELECTED_SALES_OPTION_SUCCESS',
    props<{ guide: Guide }>(),
  );

  export const saveDeselectedSalesOptionError = createAction(
    '[Configuration] SAVE_DESELECTED_SALES_OPTION_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const saveSelectedAttributes = createAction(
    '[Configuration] SAVE_SELECTED_ATTRIBUTES',
    props<{
      guideId: number;
      userGuid: string;
      language: string;
      selectedAttributeValue: SelectedAttributeValue;
    }>(),
  );

  export const saveSelectedAttributesSuccess = createAction(
    '[Configuration] SAVE_SELECTED_ATTRIBUTES_SUCCESS',
    props<{ guide: Guide }>(),
  );

  export const saveSelectedAttributesError = createAction(
    '[Configuration] SAVE_SELECTED_ATTRIBUTES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const saveSliderValues = createAction(
    '[Configuration] SAVE_SLIDER_VALUES',
    props<{ selectedSliderValues: { [name: string]: number | undefined } }>(),
  );
  export const resetSliderValues = createAction(
    '[Configuration] RESET_SLIDER_VALUES',
  );

  export const saveSelectedColor = createAction(
    '[Configuration] SAVE_SELECTED_COLOR',
    props<{ activeColor: string; coloredPart: string }>(),
  );

  export const saveModelImage = createAction(
    '[Configuration] SAVE_MODEL_IMAGE',
    props<{ modelImage: string | undefined }>(),
  );
}
