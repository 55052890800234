/**
 * @internal
 */
export class NativeShaderProcessingContext {
  constructor() {
    this.vertexBufferKindToNumberOfComponents = {};
    this.remappedAttributeNames = {};
    this.injectInVertexMain = "";
  }
}
