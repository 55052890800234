import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  Guide,
  SalesOption,
  SalesOptionGroupType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { SlScSalesOptionDialogComponent } from '@sales-libs/sc-configuration/ui';
import { ScSharedActions } from '@sales-libs/shared/feature';
import { Observable } from 'rxjs';
import {
  SlScConfigurationActions,
  SlScConfigurationSelectors,
} from '../../store';
import { SlScSalesOptionDetailsDialogComponent } from '../sales-option-details-dialog/sales-option-details-dialog.component';
import { SlScSalesOptionSubgroupComponent } from '../sales-option-subgroup/sales-option-subgroup.component';

export const imports = [
  CommonModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatTooltipModule,
  TranslateModule,
  MatIconButton,
];

@Component({
  selector: 'sl-sc-sales-option-list-multiple',
  standalone: true,
  imports: [forwardRef(() => SlScSalesOptionSubgroupComponent), imports],
  templateUrl: './sales-option-list-multiple.component.html',
  styleUrls: ['./sales-option-list-multiple.component.scss'],
})
export class SlScSalesOptionListMultipleComponent implements OnInit {
  @Input()
  salesOptions: SalesOption[];
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  @Input()
  sectionName: string;
  selectedLanguage: string;

  readonly StateEnum = SalesOptionState;
  readonly SalesOptionGroupTypeEnum = SalesOptionGroupType;
  readonly showDebugView$: Observable<boolean>;
  clickedSalesOptionDebug: SalesOption[] | undefined;
  isGroupLoading$: Observable<boolean>;
  selectedValue: string;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private actions: Actions,
  ) {
    this.showDebugView$ = this.store.pipe(
      select(SlScConfigurationSelectors.showDebugView),
    );
    this.isGroupLoading$ = this.store.pipe(
      select(SlScConfigurationSelectors.isGroupLoading),
    );
  }

  ngOnInit(): void {
    this.getLanguageFromURL();
  }

  getLanguageFromURL(): void {
    this.selectedLanguage = this.route.snapshot.params?.language;
  }

  selectSalesOption(salesOption: SalesOption) {
    if (salesOption.code) this.selectedValue = salesOption.code;

    if (salesOption.state === this.StateEnum.Selected) {
      this.store.dispatch(
        SlScConfigurationActions.saveDeselectedSalesOption({
          guideId: this.guide?.id ?? 0,
          userGuid: this.userGuid,
          language: this.selectedLanguage,
          deselectedSalesOption: salesOption.id,
        }),
      );
    } else {
      const selectedSalesOptionIds = { sales_options_ids: [salesOption.id] };

      this.store.dispatch(
        SlScConfigurationActions.saveSelectedSalesOption({
          guideId: this.guide?.id ?? 0,
          userGuid: this.userGuid,
          language: this.selectedLanguage,
          selectedSalesOptions: selectedSalesOptionIds,
          selectedSalesOptionName: salesOption.code,
        }),
      );
    }

    this.actions
      .pipe(
        ofType(
          SlScConfigurationActions.saveSelectedSalesOptionSuccess,
          SlScConfigurationActions.saveDeselectedSalesOptionSuccess,
        ),
      )
      .subscribe((guide) => {
        this.store.dispatch(ScSharedActions.SetGuide(guide));
        this.selectedValue = '';
      });
  }

  showSalesOptionStateReason(salesOption: SalesOption): void {
    this.clickedSalesOptionDebug = this.salesOptions.filter(
      (singleSalesOption) => singleSalesOption.id === salesOption.id,
    );

    this.dialog.open(SlScSalesOptionDialogComponent, {
      width: '500px',
      data: this.clickedSalesOptionDebug,
    });
  }

  openInfoDialog(salesOption: SalesOption): void {
    const dialogRef = this.dialog.open(SlScSalesOptionDetailsDialogComponent, {
      width: '850px',
      data: { salesOption: salesOption, sectionName: this.sectionName },
      panelClass: 'so-details-dialog',
      autoFocus: false,
    });

    const instance = dialogRef.componentInstance;
    instance.showAddButton = true;

    instance.salesOptionSelected.subscribe((result) => {
      this.selectSalesOption(result);
      const selectedSalesOption = { ...result };
      selectedSalesOption.state = SalesOptionState.Selected;
      dialogRef.componentInstance.data.salesOption = selectedSalesOption;
    });
  }
}
