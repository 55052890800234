/* eslint-disable max-lines */
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FlexModule, MediaObserver } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { dsConfig } from '@design-system/cdk/config';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import {
  DsImageCarouselModule,
  Slide,
} from '@design-system/components/image-carousel';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  Analytics,
  MediaType,
  MetaTagsService,
  SlScCurrencySymbolPipe,
  SlScDescriptionDecorationPipe,
  deformatUrlFriendlyName,
  formatUrlFriendlyName,
  populateUrlFriendlyProductLineEnum,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject, take, takeUntil } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  SlScConfigurationSelectors,
  SlScSalesOptionDetailsDialogComponent,
} from '@sales-libs/sc-configuration/feature';
import {
  Currency,
  Guide,
  ModelDetail,
  SalesOption,
  UserConfiguration,
  UserGuide,
} from '@sales-libs/sc-summary/data-access';
import { SectionType } from '@sales-libs/shared/data-access/sc-generated';
import {
  SlSharedScFooterComponent,
  SlSharedScProductLineIconsComponent,
} from '@sales-libs/shared/ui';
import {
  GoogleAnalytics4DirectiveModule,
  GoogleAnalyticsClickListenerDirective,
} from '@shared-lib/google-analytics';
import { SlScContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import { SlScExportDialogComponent } from '../export-dialog/export-dialog.component';
import { SlScSummaryActions, SlScSummarySelectors } from '../store';

type stringType = string | null | undefined;
export const imports = [
  CommonModule,
  ClipboardModule,
  SlScDescriptionDecorationPipe,
  SlSharedScProductLineIconsComponent,
  SlSharedScFooterComponent,
  SlScCurrencySymbolPipe,
  DsSpotlightModule,
  DsImageCarouselModule,
  DsSpacingModule,
  DsLoadingModule,
  MatSelectModule,
  MatIconModule,
  MatCardModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatExpansionModule,
  MatButtonModule,
  MatMenuModule,
  MatDialogModule,
  FlexModule,
  GoogleAnalytics4DirectiveModule,
  GoogleAnalyticsClickListenerDirective,
  TranslateModule,
];

@Component({
  selector: 'sl-sc-summary',
  standalone: true,
  imports: imports,
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SlScSummaryComponent implements OnInit, OnDestroy {
  @Input() scUrl?: string;
  @Input() isSC?: boolean = false;
  @Output() initNewStart = new EventEmitter();
  @Output() backToConfigurationStep = new EventEmitter<{
    sectionName: string;
  }>();
  scUserGuid: string | null | undefined;
  guide: Guide;
  userGuide: UserGuide;
  scProductLineName: string;
  userConfiguration$: Observable<any>;
  userConfiguration: UserConfiguration;
  scSelectedLanguage: string;
  configurationLink: string;
  configurationCode: stringType;
  emailLink: string;
  actionCopy = 'copy';
  actionEmail = 'email';
  actionExport = 'export';
  shareableLink: string;
  stepName: string;
  @Input() summaryStepName: string;
  supportedCurrencies$: Observable<Currency[]>;
  selectedCurrency: string;
  userCurrency: string;
  modelDetails: ModelDetail[] = [];
  compWindow: any;

  readonly SectionType = SectionType;
  readonly MediaType = MediaType;
  readonly Analytics = Analytics;
  private readonly destroy$ = new Subject<void>();

  @ViewChildren(MatAccordion)
  readonly Accordions: QueryList<MatAccordion>;
  isExpanded = true;
  slideUrl: string;
  isLoading$: Observable<boolean | undefined>;
  slides: Slide[] = [
    {
      src: './assets/img/placeholder-img.jpg',
    },
  ];
  isSoMoreDetailsVisible = true;
  productLineEnum: { [key: string]: string } = {};
  urlFriendlyProductLineEnum: { [key: string]: string } = {};
  englishLanguageCode = 'en';
  displaySpotlight: boolean;
  modelSS: (string | undefined)[];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private readonly clipboard: Clipboard,
    private snackbar: DsSnackbar,
    private metaTagsService: MetaTagsService,
    public media: MediaObserver,
    public actions: Actions,
  ) {
    this.shareableLink = this.scUrl + `/share/`;
    this.isLoading$ = this.store.pipe(select(SlScSummarySelectors.isLoading));
    this.store
      .pipe(select(SlScConfigurationSelectors.modelSS), filterTruthy(), take(1))
      .subscribe((modelSS) => {
        this.modelSS = modelSS;
      });
    this.compWindow = window;
  }

  ngOnInit(): void {
    this.getSharedUserGuide();
    this.getParamsFromSharedStore();
    this.getGuideFromStore();
    this.getProductLineEnum();
    this.getUserConfiguration();
    this.setPageTitle();
    this.getModelDetails();
    this.selectedCurrency =
      this.guide?.price?.currency_code ??
      this.userGuide?.currency_code ??
      'EUR';
  }

  getParamsFromSharedStore(): void {
    this.store
      .pipe(
        select(ScSharedSelectors.sharedState),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((state) => {
        if (state.scShared.guide?.user_guid)
          this.scUserGuid =
            state.scShared.userGuid ?? state.scShared.guide?.user_guid;
        this.scSelectedLanguage =
          state.scShared.selectedLanguageCode ?? this.englishLanguageCode;
        this.scProductLineName = state.scShared.productLineName;
        this.stepName = state.scShared.currentSection?.name ?? '';
      });
  }

  getGuideFromStore(): void {
    this.store
      .pipe(
        select(ScSharedSelectors.guide),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((guide) => {
        this.guide = guide;
        this.getProductLineEnum();
        this.getCurrencies();
        this.displaySpotlight = this.guide.settings?.display_spotlight ?? false;
      });
  }

  getProductLineEnum(): void {
    if (this.router.url.includes('/share/')) {
      this.store
        .pipe(
          select(SlScSummarySelectors.userConfiguration),
          filterTruthy(),
          take(1),
        )
        .subscribe((userConfiguration) => {
          const guideKey = userConfiguration.product_line.key;
          const guideUrlName = Object.keys(userConfiguration.product_line)[0];
          this.urlFriendlyProductLineEnum = { [guideKey]: guideUrlName };
          this.scProductLineName = guideUrlName ?? '';
        });
    } else {
      this.store
        .pipe(select(ScSharedSelectors.userGuides), filterTruthy(), take(1))
        .subscribe((guides) => {
          this.urlFriendlyProductLineEnum =
            populateUrlFriendlyProductLineEnum(guides);
        });
    }
  }

  getModelDetails() {
    this.modelDetails = this.guide?.model_details ?? [];
  }

  getUserConfigurationModelDetails() {
    this.modelDetails = this.userConfiguration?.model_details ?? [];
  }

  setPageTitle(): void {
    if (this.summaryStepName) {
      this.metaTagsService.setMetaTitleDescriptionKeywords(
        deformatUrlFriendlyName(this.summaryStepName),
      );
    } else {
      this.translateService
        .get('turnkey_solution.common.summary')
        .subscribe((translatedKey) => {
          this.metaTagsService.setMetaTitleDescriptionKeywords(translatedKey);
        });
    }
  }

  getSharedUserGuide(): void {
    this.store
      .pipe(
        select(SlScSummarySelectors.userGuide),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((userGuide) => {
        this.userGuide = userGuide;
      });
  }

  getUserConfiguration(): void {
    const userGuideLanguage =
      this.userGuide?.language_code?.toLowerCase() ?? this.englishLanguageCode;

    this.store.dispatch(
      SlScSummaryActions.getUserConfiguration({
        guideId: this.guide?.id || this.userGuide?.guide_id,
        guid: this.scUserGuid ?? this.userGuide?.user_guid,
        language: this.scSelectedLanguage || userGuideLanguage,
        currencyCode: this.selectedCurrency,
      }),
    );
    this.userConfiguration$ = this.store.pipe(
      select(SlScSummarySelectors.userConfiguration),
      filterTruthy(),
    );

    this.store
      .pipe(select(SlScSummarySelectors.userConfiguration), filterTruthy())
      .subscribe((userConfiguration) => {
        this.userConfiguration = userConfiguration;
        const slidesWithModelSS = [
          ...userConfiguration.slides,
          {
            headline: 'Model with selected Sales Options',
            description: '',
            src: this.modelSS,
          },
        ];
        if (
          this.scProductLineName === this.urlFriendlyProductLineEnum.TimberCrane
        ) {
          this.slides = slidesWithModelSS;
        } else {
          this.slides = userConfiguration.slides;
        }
        this.getUserConfigurationModelDetails();
      });
  }

  changeUserLanguage(scSelectedLanguage: string) {
    if (
      scSelectedLanguage &&
      scSelectedLanguage !== this.translateService.currentLang
    ) {
      this.translateService.use(scSelectedLanguage.toLowerCase());
    }
  }

  getCurrencies(): void {
    this.store.dispatch(
      SlScSummaryActions.getCurrencies({
        guideId: this.guide?.id || this.userGuide?.guide_id,
      }),
    );
    this.supportedCurrencies$ = this.store.pipe(
      select(SlScSummarySelectors.currencies),
      filterTruthy(),
    );
  }

  changeSelectedCurrency(selectedCurrency: string) {
    this.selectedCurrency = selectedCurrency;

    this.userCurrency = selectedCurrency;
    if (this.guide?.id || this.userGuide?.guide_id) this.getUserConfiguration();
  }

  goToConfigurationSection(
    e: MouseEvent,
    sectionName: string | undefined | null,
  ): void {
    e.stopPropagation();
    sectionName = formatUrlFriendlyName(sectionName);

    if (sectionName) this.backToConfigurationStep.emit({ sectionName });
  }

  openContactFormDialog() {
    this.dialog.open(SlScContactFormDialogComponent, {
      width: '500px',
      data: {
        guid: this.scUserGuid ?? this.userGuide?.user_guid,
        guideId: this.guide.id || this.userGuide?.guide_id,
        productLineName: this.scProductLineName,
        scSelectedLanguage: this.scSelectedLanguage,
        scUrl: this.scUrl,
      },
    });
  }

  openInfoDialog(
    salesOption: SalesOption,
    sectionName: string | undefined | null,
  ): void {
    this.dialog.open(SlScSalesOptionDetailsDialogComponent, {
      width: String(dsConfig.spacing * 30) + 'px',
      data: { salesOption: salesOption, sectionName: sectionName },
      autoFocus: false,
    });
  }

  openExportDialog(): void {
    this.getConfigurationCode(this.actionExport);
  }

  openExportDialogWithData(): void {
    this.dialog.open(SlScExportDialogComponent, {
      width: '500px',
      data: {
        guid: this.scUserGuid ?? this.userGuide?.user_guid,
        guideId: this.guide.id || this.userGuide?.guide_id,
        configurationCode: this.configurationCode,
        currency: this.selectedCurrency,
        enableSoDescriptions: this.isSoMoreDetailsVisible,
        scSelectedLanguage: this.scSelectedLanguage,
        scUrl: this.scUrl,
      },
    });
  }

  getConfigurationCode(clickAction: string): void {
    this.store.dispatch(
      SlScSummaryActions.getConfigurationCode({
        guideId: this.guide?.id || this.userGuide.guide_id,
        guid: this.scUserGuid ?? this.userGuide.user_guid,
        language:
          this.userGuide?.language_code ??
          this.scSelectedLanguage.toLowerCase(),
        currencyCode: this.selectedCurrency,
      }),
    );

    this.store
      .pipe(
        select(SlScSummarySelectors.configurationCode),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((configurationCode) => {
        this.configurationCode = configurationCode;
      });

    this.actions
      .pipe(ofType(SlScSummaryActions.getConfigurationCodeSuccess), take(1))
      .subscribe(() => {
        switch (clickAction) {
          case this.actionCopy:
            return this.createCopyLinkAndShowMessage();
          case this.actionEmail:
            return this.createEmailLink();
          case this.actionExport:
            return this.openExportDialogWithData();
          default:
            return undefined;
        }
      });
  }

  sendEmail(): void {
    this.getConfigurationCode(this.actionEmail);
  }

  private createEmailLink(): void {
    const emailSubject = this.translateService
      .instant('turnkey_solution.common.email_subject')
      .replace('{0}', this.userConfiguration.selected_model);

    this.configurationLink = this.scUrl + `/share/${this.configurationCode}`;

    this.emailLink =
      'mailto:?Subject=' +
      emailSubject +
      '&body=%0D%0A%0D%0A' +
      this.configurationLink;
    this.compWindow.location.href = this.emailLink;
  }

  onCopy(): void {
    this.getConfigurationCode(this.actionCopy);
  }

  createCopyLinkAndShowMessage(): void {
    this.configurationLink = this.scUrl + `/share/${this.configurationCode}`;
    this.clipboard.copy(this.configurationLink);

    this.snackbar.queue(
      this.translateService.instant(
        'turnkey_solution.common.copy_link_message',
      ),
      {
        type: DsSnackbarType.Info,
      },
    );
  }

  closeAllPanels() {
    this.isExpanded = false;
    this.Accordions.forEach((accordion) => accordion.closeAll());
  }

  openAllPanels() {
    this.isExpanded = true;
    this.Accordions.forEach((accordion) => accordion.openAll());
  }

  toggleMoreDetails() {
    this.store.dispatch(SlScSummaryActions.toggleSoMoreDetails());
    this.isSoMoreDetailsVisible = !this.isSoMoreDetailsVisible;
  }

  handleStartNewConfiguration() {
    this.initNewStart.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.store.dispatch(SlScSummaryActions.resetToggleSoMoreDetails());
  }
}
