/* eslint-disable max-lines */
import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  forwardRef,
} from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Guide,
  SalesOptionCategories,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionState,
  Section,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  SlSharedScFooterComponent,
  SlSharedScProductLineIconsComponent,
} from '@sales-libs/shared/ui';
import {
  Analytics,
  MetaTagsService,
  SectionType,
  formatUrlFriendlyName,
  populateUrlFriendlyProductLineEnum,
} from '@sales-libs/shared/util';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SlScConfigurationActions, SlScConfigurationSelectors } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SlScSummaryComponent } from '@sales-libs/sc-summary/feature';
import { SlScProductViewerComponent } from '../product-viewer/product-viewer.component';
import { SlScSalesOptionCategoryListComponent } from '../sales-options/sales-option-category-list/sales-option-category-list.component';

type stringType = string | null | undefined;

export const imports = [
  CommonModule,
  FlexModule,
  FlexLayoutModule,
  TranslateModule,
  GoogleAnalyticsClickListenerDirective,
  FormsModule,
  ReactiveFormsModule,
  SlSharedScProductLineIconsComponent,
  SlScProductViewerComponent,
  SlScSalesOptionCategoryListComponent,
  SlSharedScFooterComponent,
  MatTabsModule,
  MatIconModule,
  MatTooltipModule,
  MatCardModule,
  MatSlideToggleModule,
  MatButtonModule,
  MatMenuModule,
  MatBadgeModule,
  DsSpacingModule,
  DsLoadingModule,
  DsSnackbarModule,
  forwardRef(() => SlScSummaryComponent),
];

@Component({
  selector: 'sl-sc-configuration',
  standalone: true,
  imports: imports,
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class SlScConfigurationComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  @Input() isSC?: boolean = false;
  @Input() scUrl?: string;
  @Input()
  displayConfigurationDebuggingToggleFeatureFlag: string;

  @Output() goToStep = new EventEmitter<{
    currentStepIndex: number;
  }>();
  @Output() goToSummary = new EventEmitter<Section[]>();
  @Output() goToSolution = new EventEmitter();
  @Output() initNewStart = new EventEmitter();

  @ViewChild('productViewerComponent', { read: ViewContainerRef })
  productViewerComponent!: ViewContainerRef;
  displayModel = false;

  userGuid: string;
  configurationForm!: FormGroup;
  guide: Guide;

  sectionType = SectionType.Configuration;
  selectedModelName: stringType;
  currentStepIndex: number;
  requiredStepIndex: number;
  currentSectionId: number;
  sectionModelImage: stringType;
  modelImage: stringType;
  sectionName: string | null;

  scUserGuid: string;
  scSelectedLanguage: string;
  scProductLineName: string;
  scCurrentSection: Section | undefined;

  salesOptionCategories: SalesOptionCategories | null | undefined;
  configurationSections: Section[] | null | undefined;
  configurationSectionsValidity: boolean[] = [];
  isLoading$: Observable<boolean>;
  isDisabledSummaryButton: boolean;
  selectedSliderValues: { [name: string]: number | undefined };
  platformSectionName = 'Platform';
  summarySectionName: string;
  showPrice: boolean;
  totalPrice: number | undefined;
  totalWeight: stringType;
  totalWeightUnit: stringType;
  priceCurrency: string | undefined;
  urlFriendlyProductLineEnum: { [key: string]: string } = {};
  solutionSection: Section | undefined;
  goToSummaryClick = false;

  readonly StateEnum = SalesOptionState;
  readonly Analytics = Analytics;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private _changeDetection: ChangeDetectorRef,
    public actions: Actions,
    private metaTagsService: MetaTagsService,
  ) {
    this.isLoading$ = this.store.pipe(select(ScSharedSelectors.isLoading));
  }

  ngOnInit() {
    this.getParamsFromSharedStore();
    this.initializeForm();
    this.checkSliderValidityOnChange();
    this.getProductLineEnum();
  }

  getParamsFromSharedStore(): void {
    this.store
      .pipe(
        select(ScSharedSelectors.sharedState),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((state) => {
        this.scUserGuid = state.scShared.userGuid;
        this.scSelectedLanguage = state.scShared.selectedLanguageCode;
        this.scProductLineName = state.scShared.productLineName;
        this.scCurrentSection = state.scShared.currentSection;
        this.sectionName = formatUrlFriendlyName(
          this.scCurrentSection?.name ?? '',
        );

        if (this.scCurrentSection)
          this.updateAll(
            this.scSelectedLanguage,
            this.scCurrentSection,
            this.guide,
          );

        this.getGuideFromSharedStore();
      });
  }

  updateAll(
    selectedLanguage: string,
    currentSection: Section | undefined,
    guide?: Guide,
  ): void {
    const section = guide?.sections?.find(
      (singleSection) => singleSection.id === currentSection?.id,
    );
    this.changeUserLanguage(selectedLanguage);
    this.setPageMetadata(section);
    this.setCurrentStepIndex();
  }

  getProductLineEnum(): void {
    this.store
      .pipe(select(ScSharedSelectors.userGuides), filterTruthy(), take(1))
      .subscribe((guides) => {
        this.urlFriendlyProductLineEnum =
          populateUrlFriendlyProductLineEnum(guides);
      });
  }

  ngAfterContentChecked() {
    this._changeDetection.detectChanges();
  }

  initializeForm(): void {
    this.configurationForm = this.formBuilder.group({});
  }

  getGuideFromSharedStore(): void {
    this.store
      .pipe(
        select(ScSharedSelectors.guide),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe((guide) => {
        this.guide = guide;
        this.getConfigurationSections();
        this.setCurrentStepIndex();
        this.getSalesOptionCategoriesForCurrentStep(this.currentStepIndex);
        this.getSelectedModelName();
        this.getSelectedSliderValues();
        this.checkSectionsValidity();
        this.getSectionImage();
      });
  }

  getConfigurationSections() {
    this.configurationSections = this.guide?.sections?.filter(
      (section) => section.type === this.sectionType,
    );

    if (this.configurationSections)
      this.summarySectionName =
        this.configurationSections[
          this.configurationSections?.length - 1
        ]?.name?.toLowerCase() ?? '';
  }

  getSelectedModelName(): void {
    this.selectedModelName = this.guide?.model?.name;
  }

  getSectionImage(): void {
    this.modelImage = this.guide?.model?.image_uri;

    const modelImageObj = this.guide?.images?.find((image) =>
      image.section_ids?.find((id) => id === this.currentSectionId),
    );

    this.sectionModelImage = modelImageObj?.image_uri;
  }

  checkSectionsValidity() {
    this.configurationSections?.map((section, index) => {
      this.checkValidity(section, index);
    });

    this.checkRequiredStepIndex();
  }

  getTotalPriceAndWeight(): void {
    const stepName = this.scCurrentSection?.name ?? '';

    if (
      stepName === this.platformSectionName.toLowerCase() &&
      (this.selectedSliderValues.height === 0 ||
        this.selectedSliderValues.width === 0)
    ) {
      this.showPrice = false;
    } else {
      this.showPrice = true;
      const price = this.guide?.price;
      this.totalPrice = price?.total_price;
      if (price?.currency_code) this.priceCurrency = price?.currency_code;
    }

    this.totalWeight = this.guide?.weight?.formatted_weight;
    this.totalWeightUnit = this.guide?.weight?.unit;
  }

  setCurrentStepIndex() {
    const stepName = this.scCurrentSection?.name ?? '';
    const section = this.guide?.sections?.find(
      (singleSection) =>
        (formatUrlFriendlyName(singleSection.name) === stepName.toLowerCase() ||
          singleSection.name === stepName) &&
        singleSection.type === this.sectionType,
    );

    if (section) {
      this.currentStepIndex = this.configurationSections?.indexOf(section) ?? 0;
      this.currentSectionId = section.id;
    }
  }

  navigateToStep(currentStepIndex: number): void {
    this.goToStep.emit({ currentStepIndex });
  }

  navigateToConfigurationStep(sectionName: string): void {
    const section = this.guide?.sections?.find(
      (singleSection) =>
        (formatUrlFriendlyName(singleSection.name) ===
          sectionName.toLowerCase() ||
          singleSection.name === sectionName) &&
        singleSection.type === this.sectionType,
    );

    if (section) {
      this.currentStepIndex = this.configurationSections?.indexOf(section) ?? 0;
      this.goToStep.emit({ currentStepIndex: this.currentStepIndex });
    }
  }

  getSalesOptionCategoriesForCurrentStep(step: number) {
    if (this.configurationSections) {
      this.salesOptionCategories = {
        data: this.configurationSections[step]?.sales_option_categories ?? [],
      };
    }
  }

  checkValidity(configurationSection: Section, index: number): void {
    const allRequiredGroups =
      configurationSection.sales_option_categories?.flatMap((category) =>
        category?.sales_option_groups?.filter((group) => group.is_required),
      );

    const sliderGroups = allRequiredGroups?.filter(
      (group) => group?.type === SalesOptionGroupType.AttributeBased,
    );

    if (sliderGroups?.length) {
      const attributesHaveValue = sliderGroups[0]?.attributes?.every(
        (attribute) => attribute.selected_value,
      );

      this.configurationSectionsValidity[index] = !!attributesHaveValue;
    } else {
      const areAllRequiredGroupsSelected =
        this.areAllRequiredGroupsSelected(allRequiredGroups);

      const allRequiredChildGroups =
        configurationSection.sales_option_categories?.flatMap((category) =>
          category?.sales_option_groups?.flatMap((group) =>
            group?.sales_options?.flatMap((sales_option) =>
              this.getRequiredChildGroups(sales_option.groups),
            ),
          ),
        );

      const allRequiredChildGroupsInSubgroups =
        configurationSection.sales_option_categories?.flatMap((category) =>
          category?.sales_option_groups?.flatMap((group) =>
            group?.subgroups?.flatMap((subgroup) =>
              subgroup?.sales_options?.flatMap((sales_option) =>
                this.getRequiredChildGroups(sales_option.groups),
              ),
            ),
          ),
        );

      const areAllRequiredChildGroupsSelected =
        this.areAllRequiredSubgroupsSelected(allRequiredChildGroups);
      const areAllRequiredChildGroupsInSubgroupsSelected =
        this.areAllRequiredSubgroupsSelected(allRequiredChildGroupsInSubgroups);

      this.configurationSectionsValidity[index] =
        areAllRequiredGroupsSelected &&
        areAllRequiredChildGroupsSelected &&
        areAllRequiredChildGroupsInSubgroupsSelected;
    }
  }

  getRequiredChildGroups(
    salesOptionGroups: SalesOptionGroup[] | null | undefined,
  ) {
    return salesOptionGroups?.filter((childGroup) => childGroup.is_required);
  }

  checkSliderValidityOnChange(): void {
    this.actions
      .pipe(ofType(SlScConfigurationActions.saveSelectedAttributesSuccess))
      .subscribe((guide) => {
        this.store.dispatch(ScSharedActions.SetGuide(guide));
        this.checkSliderValidity();
        this.checkRequiredStepIndex();
      });
  }

  checkSliderValidity(): void {
    this.configurationSections?.map((section, index) => {
      if (section.name === this.platformSectionName) {
        this.configurationSectionsValidity[index] = !(
          this.selectedSliderValues.height === 0 ||
          this.selectedSliderValues.width === 0
        );
      }
    });
  }

  checkRequiredStepIndex() {
    this.requiredStepIndex = this.configurationSectionsValidity.indexOf(false);
    // disable Summary if any required group is not selected
    this.isDisabledSummaryButton = this.requiredStepIndex !== -1;
  }

  private areAllRequiredGroupsSelected(allRequiredGroups): boolean {
    return allRequiredGroups?.every((group) =>
      group?.sales_options?.some((so) => so.state === this.StateEnum.Selected),
    );
  }
  private areAllRequiredSubgroupsSelected(
    allRequiredSubgroupsSelected,
  ): boolean {
    return allRequiredSubgroupsSelected?.every((subgroup) =>
      subgroup?.sales_options?.some(
        (so) => so.state === this.StateEnum.Selected,
      ),
    );
  }

  getSelectedSliderValues(): void {
    this.store
      .pipe(
        select(SlScConfigurationSelectors.selectedSliderValues),
        filterTruthy(),
      )
      .subscribe((sliderValues) => {
        this.selectedSliderValues = sliderValues ?? {};
        this.checkSectionsValidity();
        this.checkSliderValidity();
        this.checkRequiredStepIndex();
        this.getTotalPriceAndWeight();
      });
  }

  changeUserLanguage(selectedLanguage: string) {
    if (
      selectedLanguage &&
      selectedLanguage !== this.translateService.currentLang
    ) {
      this.translateService.use(selectedLanguage.toLowerCase());
    }
  }

  toggleDebugView(): void {
    this.store.dispatch(SlScConfigurationActions.toggleDebugView());
  }

  navigateToSummary(): void {
    this.goToSummary.emit();
  }

  navigateToSolution(): void {
    this.goToSolution.emit();
  }

  setPageMetadata(section: Section | undefined): void {
    this.metaTagsService.setMetaTitleDescriptionKeywords(
      section?.metadata?.title,
      section?.metadata?.description,
      section?.metadata?.keywords,
    );
  }

  async loadProductViewer() {
    this.displayModel = true;
    this.productViewerComponent?.clear();
    this.productViewerComponent.createComponent(SlScProductViewerComponent);
  }

  handleStartNewConfiguration() {
    this.initNewStart.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.store.dispatch(SlScConfigurationActions.resetToggleDebugView());
    this.store.dispatch(SlScConfigurationActions.resetSliderValues());
  }
}
