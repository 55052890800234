import { NodeMaterialBlock } from "../nodeMaterialBlock.js";
import { NodeMaterialBlockConnectionPointTypes } from "../Enums/nodeMaterialBlockConnectionPointTypes.js";
import { NodeMaterialBlockTargets } from "../Enums/nodeMaterialBlockTargets.js";
import { RegisterClass } from "../../../Misc/typeStore.js";
/**
 * Block used to get the distance between 2 values
 */
export class DistanceBlock extends NodeMaterialBlock {
  /**
   * Creates a new DistanceBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name, NodeMaterialBlockTargets.Neutral);
    this.registerInput("left", NodeMaterialBlockConnectionPointTypes.AutoDetect);
    this.registerInput("right", NodeMaterialBlockConnectionPointTypes.AutoDetect);
    this.registerOutput("output", NodeMaterialBlockConnectionPointTypes.Float);
    this._linkConnectionTypes(0, 1);
    this._inputs[0].excludedConnectionPointTypes.push(NodeMaterialBlockConnectionPointTypes.Float);
    this._inputs[0].excludedConnectionPointTypes.push(NodeMaterialBlockConnectionPointTypes.Matrix);
    this._inputs[1].excludedConnectionPointTypes.push(NodeMaterialBlockConnectionPointTypes.Float);
    this._inputs[1].excludedConnectionPointTypes.push(NodeMaterialBlockConnectionPointTypes.Matrix);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "DistanceBlock";
  }
  /**
   * Gets the left operand input component
   */
  get left() {
    return this._inputs[0];
  }
  /**
   * Gets the right operand input component
   */
  get right() {
    return this._inputs[1];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock(state) {
    super._buildBlock(state);
    const output = this._outputs[0];
    state.compilationString += state._declareOutput(output) + ` = length(${this.left.associatedVariableName} - ${this.right.associatedVariableName});\n`;
    return this;
  }
}
RegisterClass("BABYLON.DistanceBlock", DistanceBlock);
