import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { ActivatedRoute } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Attribute,
  Guide,
  SalesOption,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedActions } from '@sales-libs/shared/feature';
import { Observable, Subject, take } from 'rxjs';
import {
  SlScConfigurationActions,
  SlScConfigurationSelectors,
} from '../../store';

export const imports = [
  CommonModule,
  TranslateModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  DsLoadingModule,
  DsSpacingModule,
];

type stepsType = number[] | null | undefined;

@Component({
  selector: 'sl-sc-sales-option-slider',
  standalone: true,
  imports,
  templateUrl: './sales-option-slider.component.html',
  styleUrls: ['./sales-option-slider.component.scss'],
})
export class SlScSalesOptionSliderComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  salesOptions: SalesOption[];
  @Input()
  attributes: Attribute[];
  @Input()
  salesOptionsGroupId: number;
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  @Output()
  salesOptionSelected: EventEmitter<string> = new EventEmitter();
  selectedLanguage: string;

  maxHeight: number;
  stepIndex: number;
  steps: stepsType;
  finalHeightValue: string | number;
  heightValue: number;
  selectedHeightValue;
  disabledHeight = false;

  maxWidth: number;
  stepsWidth: stepsType;
  finalWidthValue: string | number;
  disabledWidth: boolean;
  widthValue: number;

  isAttributeSubmitting$: Observable<boolean>;
  isHeightActive: boolean;

  selectValueLabel: string | number;

  readonly StateEnum = SalesOptionState;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    public translate: TranslateService,
    public actions: Actions,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
  ) {
    this.isAttributeSubmitting$ = this.store.pipe(
      select(SlScConfigurationSelectors.isAttributeSubmitting),
    );

    this.selectValueLabel = this.translate.instant(
      'turnkey_solution.common.please_select',
    );
  }

  ngOnChanges(): void {
    this.setSliders();
  }

  ngOnInit(): void {
    this.getLanguageFromURL();
    this.setSliders();
  }

  getLanguageFromURL(): void {
    this.selectedLanguage = this.route.snapshot.params?.language;
  }

  setSliders(): void {
    this.disabledHeight = true;
    if (this.attributes) {
      this.initHeightSlider(this.attributes[0]?.values);
      this.initWidthSlider(this.attributes[1]?.values);

      const heightSelectedAttribute = this.attributes[0]?.selected_value;
      const widthSelectedAttribute = this.attributes[1]?.selected_value;
      this.setHeightSlider(heightSelectedAttribute);
      this.setWidthSlider(widthSelectedAttribute);

      if (this.widthValue === undefined && this.heightValue === undefined) {
        this.heightValue = heightSelectedAttribute;
        this.widthValue = widthSelectedAttribute;
      }

      this.saveSliderValuesToStore(
        heightSelectedAttribute,
        widthSelectedAttribute === this.widthValue
          ? widthSelectedAttribute
          : this.widthValue,
      );
    }
    this.disabledHeight = false;
  }

  initHeightSlider(heightAttribute: stepsType): void {
    if (heightAttribute?.length) this.maxHeight = heightAttribute?.length - 1;
    if (this.widthValue === 0) this.stepIndex = 0;
    this.steps = heightAttribute;
  }
  initWidthSlider(widthAttribute: stepsType): void {
    this.stepsWidth = widthAttribute;
    if (widthAttribute?.length) this.maxWidth = widthAttribute?.length - 1;
  }

  setHeightSlider(heightSelectedAttribute: number): void {
    if (heightSelectedAttribute) {
      const heightExists = this.attributes[0]?.values?.find(
        (height) => height === heightSelectedAttribute,
      );
      if (heightExists === undefined) {
        this.disabledHeight = true;
      }
      this.finalHeightValue = heightSelectedAttribute;
    } else {
      this.finalHeightValue = this.selectValueLabel;
      this.disabledWidth = true;
    }
    this.selectedHeightValue = this.finalHeightValue;
  }
  setWidthSlider(widthSelectedAttribute: number): void {
    if (widthSelectedAttribute === this.widthValue && this.widthValue) {
      this.finalWidthValue = widthSelectedAttribute;
    } else {
      this.finalWidthValue = this.selectValueLabel;
    }
  }

  formatLabel(value: number): any {
    if (this.steps) return this.steps[value];
  }

  formatWidthLabel(value: number): any {
    if (this.stepsWidth) return this.stepsWidth[value];
  }

  onHeightSliderChange(step: number) {
    this.isHeightActive = true;

    this.stepIndex = step ?? 0;
    if (this.stepIndex === 0) {
      this.finalHeightValue = this.finalWidthValue = this.selectValueLabel;
      if (this.steps) this.heightValue = this.steps[0];
      this.disabledWidth = true;
    } else {
      if (this.steps)
        this.finalHeightValue = this.heightValue = this.steps[this.stepIndex];
      this.disabledWidth = false;
      this.saveSelectedSliderValue(
        this.attributes[0].id,
        this.heightValue,
        this.salesOptionsGroupId,
      );

      // On Height change, preselect Width if only one is available
      this.actions
        .pipe(
          ofType(SlScConfigurationActions.saveSelectedAttributesSuccess),
          take(1),
        )
        .subscribe((guide) => {
          this.store.dispatch(ScSharedActions.SetGuide(guide));

          if (this.stepsWidth?.length === 2) {
            this.finalWidthValue = this.widthValue = this.stepsWidth[1];

            this.saveSelectedSliderValue(
              this.attributes[1].id,
              this.finalWidthValue,
              this.salesOptionsGroupId,
            );

            this.setWidthSlider(this.finalWidthValue);
          }
        });
    }

    this.saveSliderValuesToStore(this.heightValue, this.widthValue);
  }

  onWidthSliderChange(step: number) {
    this.isHeightActive = false;

    this.stepIndex = step ?? 0;
    if (this.stepIndex === 0) {
      this.finalWidthValue = this.selectValueLabel;
      if (this.stepsWidth) this.widthValue = this.stepsWidth[0];
    } else {
      this.disabledWidth = false;
      if (this.stepsWidth)
        this.finalWidthValue = this.widthValue =
          this.stepsWidth[this.stepIndex];

      this.setHeightSlider(this.heightValue);
      this.saveSelectedSliderValue(
        this.attributes[1].id,
        this.widthValue,
        this.salesOptionsGroupId,
      );
    }

    this.saveSliderValuesToStore(this.heightValue, this.widthValue);
  }

  saveSelectedSliderValue(
    attributeId: number,
    attributeValue: number,
    salesOptionGroupId: number,
  ): void {
    const selectedAttributeValue = {
      attribute_id: attributeId,
      attribute_value: attributeValue,
      sales_option_group_id: salesOptionGroupId,
    };

    this._ngZone.run(() => {
      this.store.dispatch(
        SlScConfigurationActions.saveSelectedAttributes({
          guideId: this.guide?.id ?? 0,
          userGuid: this.userGuid,
          language: this.selectedLanguage,
          selectedAttributeValue: selectedAttributeValue,
        }),
      );
    });
  }

  private saveSliderValuesToStore(
    sliderHeight: number,
    sliderWidth: number,
  ): void {
    this._ngZone.run(() => {
      this.store.dispatch(
        SlScConfigurationActions.saveSliderValues({
          selectedSliderValues: { height: sliderHeight, width: sliderWidth },
        }),
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
