import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import {
  SalesOption,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';

export const imports = [
  CommonModule,
  TranslateModule,
  MatDialogModule,
  MatButtonModule,
];

@Component({
  selector: 'sl-sc-sales-option-dialog',
  standalone: true,
  imports,
  templateUrl: './sales-option-dialog.component.html',
})
export class SlScSalesOptionDialogComponent {
  readonly salesOptionState = SalesOptionState;

  constructor(@Inject(MAT_DIALOG_DATA) public salesOptions: SalesOption[]) {}
}
