/**
 * Composed of a frame, and an action function
 */
export class AnimationEvent {
  /**
   * Initializes the animation event
   * @param frame The frame for which the event is triggered
   * @param action The event to perform when triggered
   * @param onlyOnce Specifies if the event should be triggered only once
   */
  constructor( /** The frame for which the event is triggered **/
  frame, /** The event to perform when triggered **/
  action, /** Specifies if the event should be triggered only once**/
  onlyOnce) {
    this.frame = frame;
    this.action = action;
    this.onlyOnce = onlyOnce;
    /**
     * Specifies if the animation event is done
     */
    this.isDone = false;
  }
  /** @internal */
  _clone() {
    return new AnimationEvent(this.frame, this.action, this.onlyOnce);
  }
}
