import { ShaderLanguage } from "../../Materials/shaderLanguage.js";
const varyingRegex = /(flat\s)?\s*varying\s*.*/;
/** @internal */
export class WebGL2ShaderProcessor {
  constructor() {
    this.shaderLanguage = ShaderLanguage.GLSL;
  }
  attributeProcessor(attribute) {
    return attribute.replace("attribute", "in");
  }
  varyingCheck(varying, _isFragment) {
    return varyingRegex.test(varying);
  }
  varyingProcessor(varying, isFragment) {
    return varying.replace("varying", isFragment ? "in" : "out");
  }
  postProcessor(code, defines, isFragment) {
    const hasDrawBuffersExtension = code.search(/#extension.+GL_EXT_draw_buffers.+require/) !== -1;
    // Remove extensions
    const regex = /#extension.+(GL_OVR_multiview2|GL_OES_standard_derivatives|GL_EXT_shader_texture_lod|GL_EXT_frag_depth|GL_EXT_draw_buffers).+(enable|require)/g;
    code = code.replace(regex, "");
    // Replace instructions
    code = code.replace(/texture2D\s*\(/g, "texture(");
    if (isFragment) {
      const hasOutput = code.search(/layout *\(location *= *0\) *out/g) !== -1;
      code = code.replace(/texture2DLodEXT\s*\(/g, "textureLod(");
      code = code.replace(/textureCubeLodEXT\s*\(/g, "textureLod(");
      code = code.replace(/textureCube\s*\(/g, "texture(");
      code = code.replace(/gl_FragDepthEXT/g, "gl_FragDepth");
      code = code.replace(/gl_FragColor/g, "glFragColor");
      code = code.replace(/gl_FragData/g, "glFragData");
      code = code.replace(/void\s+?main\s*\(/g, (hasDrawBuffersExtension || hasOutput ? "" : "layout(location = 0) out vec4 glFragColor;\n") + "void main(");
    } else {
      const hasMultiviewExtension = defines.indexOf("#define MULTIVIEW") !== -1;
      if (hasMultiviewExtension) {
        return "#extension GL_OVR_multiview2 : require\nlayout (num_views = 2) in;\n" + code;
      }
    }
    return code;
  }
}
