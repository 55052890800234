import { Vector2, Vector3, Vector4 } from "../../../Maths/math.vector.js";
import { RegisterClass } from "../../../Misc/typeStore.js";
import { NodeGeometryBlockConnectionPointTypes } from "../Enums/nodeGeometryConnectionPointTypes.js";
import { NodeGeometryBlock } from "../nodeGeometryBlock.js";
/**
 * Block used to step a value
 */
export class GeometryStepBlock extends NodeGeometryBlock {
  /**
   * Creates a new GeometryStepBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name);
    this.registerInput("value", NodeGeometryBlockConnectionPointTypes.AutoDetect);
    this.registerInput("edge", NodeGeometryBlockConnectionPointTypes.Float);
    this.registerOutput("output", NodeGeometryBlockConnectionPointTypes.BasedOnInput);
    this._outputs[0]._typeConnectionSource = this._inputs[0];
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Matrix);
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Geometry);
    this._inputs[0].excludedConnectionPointTypes.push(NodeGeometryBlockConnectionPointTypes.Texture);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "GeometryStepBlock";
  }
  /**
   * Gets the value operand input component
   */
  get value() {
    return this._inputs[0];
  }
  /**
   * Gets the edge operand input component
   */
  get edge() {
    return this._inputs[1];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock() {
    if (!this.value.isConnected || !this.edge.isConnected) {
      this.output._storedFunction = null;
      this.output._storedValue = null;
      return;
    }
    const func = (value, edge) => {
      if (value < edge) {
        return 0;
      }
      return 1;
    };
    this.output._storedFunction = state => {
      const source = this.value.getConnectedValue(state);
      const edge = this.edge.getConnectedValue(state);
      switch (this.value.type) {
        case NodeGeometryBlockConnectionPointTypes.Int:
        case NodeGeometryBlockConnectionPointTypes.Float:
          {
            return func(source, edge);
          }
        case NodeGeometryBlockConnectionPointTypes.Vector2:
          {
            return new Vector2(func(source.x, edge), func(source.y, edge));
          }
        case NodeGeometryBlockConnectionPointTypes.Vector3:
          {
            return new Vector3(func(source.x, edge), func(source.y, edge), func(source.z, edge));
          }
        case NodeGeometryBlockConnectionPointTypes.Vector4:
          {
            return new Vector4(func(source.x, edge), func(source.y, edge), func(source.z, edge), func(source.w, edge));
          }
      }
      return 0;
    };
    return this;
  }
}
RegisterClass("BABYLON.GeometryStepBlock", GeometryStepBlock);
