import {
  ConfigurationCode,
  Currencies,
  Enumeration,
  Form,
  UserGuide,
} from '@sales-libs/sc-summary/data-access';
import { Guides, Languages } from '@sales-libs/shared/data-access/sc-generated';

export interface SlScSummaryState {
  userConfiguration?: any;
  configurationCode?: ConfigurationCode;
  sharedConfigurationCode?: string;
  userGuide?: UserGuide;
  countries?: Enumeration[];
  salutations?: Enumeration[];
  form?: Form;
  languages?: Languages;
  currencies?: Currencies;
  isLoading?: boolean;
  showMoreSoDetails: boolean;
  userGuides?: Guides;
}

export const initialState: SlScSummaryState = {
  userConfiguration: undefined,
  configurationCode: undefined,
  sharedConfigurationCode: undefined,
  userGuide: undefined,
  form: undefined,
  countries: undefined,
  salutations: undefined,
  languages: undefined,
  isLoading: false,
  showMoreSoDetails: true,
  userGuides: undefined,
};
