import { Color3 } from "@babylonjs/core/Maths/math.color.js";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_materials_unlit";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_unlit/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_unlit {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 210;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, () => {
      return this._loadUnlitPropertiesAsync(context, material, babylonMaterial);
    });
  }
  _loadUnlitPropertiesAsync(context, material, babylonMaterial) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    const promises = new Array();
    babylonMaterial.unlit = true;
    const properties = material.pbrMetallicRoughness;
    if (properties) {
      if (properties.baseColorFactor) {
        babylonMaterial.albedoColor = Color3.FromArray(properties.baseColorFactor);
        babylonMaterial.alpha = properties.baseColorFactor[3];
      } else {
        babylonMaterial.albedoColor = Color3.White();
      }
      if (properties.baseColorTexture) {
        promises.push(this._loader.loadTextureInfoAsync(`${context}/baseColorTexture`, properties.baseColorTexture, texture => {
          texture.name = `${babylonMaterial.name} (Base Color)`;
          babylonMaterial.albedoTexture = texture;
        }));
      }
    }
    if (material.doubleSided) {
      babylonMaterial.backFaceCulling = false;
      babylonMaterial.twoSidedLighting = true;
    }
    this._loader.loadMaterialAlphaProperties(context, material, babylonMaterial);
    return Promise.all(promises).then(() => {});
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_unlit(loader));
