import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
import { Color3 } from "@babylonjs/core/Maths/math.color.js";
const NAME = "KHR_materials_specular";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_specular/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_specular {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 190;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, (extensionContext, extension) => {
      const promises = new Array();
      promises.push(this._loader.loadMaterialPropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loadSpecularPropertiesAsync(extensionContext, extension, babylonMaterial));
      return Promise.all(promises).then(() => {});
    });
  }
  _loadSpecularPropertiesAsync(context, properties, babylonMaterial) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    const promises = new Array();
    if (properties.specularFactor !== undefined) {
      babylonMaterial.metallicF0Factor = properties.specularFactor;
    }
    if (properties.specularColorFactor !== undefined) {
      babylonMaterial.metallicReflectanceColor = Color3.FromArray(properties.specularColorFactor);
    }
    if (properties.specularTexture) {
      properties.specularTexture.nonColorData = true;
      promises.push(this._loader.loadTextureInfoAsync(`${context}/specularTexture`, properties.specularTexture, texture => {
        texture.name = `${babylonMaterial.name} (Specular F0 Strength)`;
        babylonMaterial.metallicReflectanceTexture = texture;
        babylonMaterial.useOnlyMetallicFromMetallicReflectanceTexture = true;
      }));
    }
    if (properties.specularColorTexture) {
      promises.push(this._loader.loadTextureInfoAsync(`${context}/specularColorTexture`, properties.specularColorTexture, texture => {
        texture.name = `${babylonMaterial.name} (Specular F0 Color)`;
        babylonMaterial.reflectanceTexture = texture;
      }));
    }
    return Promise.all(promises).then(() => {});
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_specular(loader));
