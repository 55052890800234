import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import {
  Guide,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionSelectionType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { SlScSalesOptionCardComponent } from '../sales-option-card/sales-option-card.component';
import { SlScSalesOptionDropdownComponent } from '../sales-option-dropdown/sales-option-dropdown.component';
import { SlScSalesOptionListMultipleComponent } from '../sales-option-list-multiple/sales-option-list-multiple.component';
import { SlScSalesOptionListComponent } from '../sales-option-list/sales-option-list.component';
import { SlScSalesOptionRalColorComponent } from '../sales-option-ral-color/sales-option-ral-color.component';

export const imports = [
  SlScSalesOptionListComponent,
  SlScSalesOptionListMultipleComponent,
  MatDividerModule,
  CommonModule,
];

@Component({
  selector: 'sl-sc-sales-option-subgroup',
  standalone: true,
  imports: [
    forwardRef(() => SlScSalesOptionRalColorComponent),
    forwardRef(() => SlScSalesOptionDropdownComponent),
    forwardRef(() => SlScSalesOptionCardComponent),
    imports,
  ],
  templateUrl: './sales-option-subgroup.component.html',
  styleUrls: ['./sales-option-subgroup.component.scss'],
})
export class SlScSalesOptionSubgroupComponent implements OnInit {
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  @Input()
  selectionType: string;
  @Input()
  salesOptionGroup: SalesOptionGroup;
  @Input()
  subgroupType: string;
  @Input()
  sectionName: string;

  noSelectedSubgroupSalesOption: boolean;

  readonly SalesOptionSelectionType = SalesOptionSelectionType;
  readonly SalesOptionGroupTypeEnum = SalesOptionGroupType;
  readonly StateEnum = SalesOptionState;

  ngOnInit(): void {
    this.checkIfAnySalesOptionIsRequired();
  }

  checkIfAnySalesOptionIsRequired() {
    this.noSelectedSubgroupSalesOption =
      this.salesOptionGroup?.is_required &&
      this.salesOptionGroup?.sales_options?.filter(
        (singleChildSalesOption) =>
          singleChildSalesOption.state === this.StateEnum.Selected,
      ).length === 0;
  }
}
