import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  Guide,
  SalesOption,
  SalesOptionGroupType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { SlScConfigurationActions } from '../../store';

export const imports = [
  CommonModule,
  MatSelectModule,
  TranslateModule,
  MatIconModule,
  FormsModule,
  ReactiveFormsModule,
  FlexModule,
];

@Component({
  selector: 'sl-sc-sales-option-ral-color',
  standalone: true,
  imports,
  templateUrl: './sales-option-ral-color.component.html',
  styleUrls: ['./sales-option-ral-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlScSalesOptionRalColorComponent implements OnInit {
  @Input()
  salesOptions: SalesOption[];
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  selectedLanguage: string;
  selectedSalesOption: SalesOption | undefined;
  isDisabled: boolean | undefined;
  filteredSalesOptions = '';

  readonly SalesOptionGroupTypeEnum = SalesOptionGroupType;
  readonly StateEnum = SalesOptionState;
  oneSalesOptionIsRequired = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getLanguageFromURL();
    this.prepareDropdownData();
  }

  getLanguageFromURL(): void {
    this.selectedLanguage = this.route.snapshot.params?.language;
  }

  prepareDropdownData(): void {
    if (
      this.salesOptions?.some(
        (salesOption) => salesOption.state === this.StateEnum.Required,
      )
    )
      this.oneSalesOptionIsRequired = true;

    this.isDisabled = this.salesOptions?.every(
      (salesOption: SalesOption) =>
        salesOption.state === this.StateEnum.Invalid ||
        (salesOption.state !== this.StateEnum.Required &&
          this.oneSalesOptionIsRequired),
    );

    this.selectedSalesOption = this.salesOptions?.find(
      (salesOption: SalesOption) =>
        salesOption.state === this.StateEnum.Selected ||
        salesOption.state === this.StateEnum.Required,
    );
  }

  selectSalesOption($event: MatSelectChange) {
    const selectedSalesOptionIds = { sales_options_ids: [$event.value.id] };

    this.store.dispatch(
      SlScConfigurationActions.saveSelectedColor({
        activeColor: $event.value.color,
        coloredPart: $event.value.code,
      }),
    );

    this.store.dispatch(
      SlScConfigurationActions.saveSelectedSalesOption({
        guideId: this.guide?.id ?? 0,
        userGuid: this.userGuid,
        language: this.selectedLanguage,
        selectedSalesOptions: selectedSalesOptionIds,
        selectedSalesOptionName: $event.value.code,
      }),
    );
  }

  clearFilteredSalesOptions(): void {
    this.filteredSalesOptions = '';
  }
}
