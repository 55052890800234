<div fxLayout="column wrap">
  <mat-form-field>
    <mat-label>{{
      'turnkey_solution.common.please_select' | translate
    }}</mat-label>
    <mat-select
      [(value)]="selectedSalesOption"
      [disabled]="isDisabled"
      [placeholder]="'turnkey_solution.common.please_select' | translate"
      (selectionChange)="selectSalesOption($event)"
      data-test="so_color_select"
    >
      <div fxLayout="row" class="search-wrap">
        <input
          [placeholder]="'turnkey_solution.common.search' | translate"
          class="search-field"
          [(ngModel)]="filteredSalesOptions"
          (keydown)="$event.stopPropagation()"
        />
        <button
          mat-icon-button
          *ngIf="filteredSalesOptions"
          type="button"
          (click)="clearFilteredSalesOptions()"
          data-test="so_color_clear"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <mat-option [value]="selectedSalesOption" class="hidden-option">{{
        selectedSalesOption?.name
      }}</mat-option>
      <mat-option
        *ngFor="let salesOption of salesOptions"
        [value]="salesOption"
        [class.hidden-option]="
          !!filteredSalesOptions &&
          !salesOption.name?.toLowerCase()?.includes(filteredSalesOptions)
        "
        data-test="so_color_option"
      >
        <span
          class="color-span"
          [ngStyle]="{ 'background-color': salesOption?.color }"
        ></span>
        {{ salesOption.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div
    *ngIf="selectedSalesOption"
    class="color-block"
    [ngStyle]="{ 'background-color': selectedSalesOption.color }"
  ></div>
</div>
