import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlScSummaryState } from './summary.store';

export namespace SlScSummarySelectors {
  export const summaryState =
    createFeatureSelector<SlScSummaryState>('SlScSummaryState');

  export const userConfiguration = createSelector(
    summaryState,
    (state) => state?.userConfiguration,
  );

  export const configurationCode = createSelector(
    summaryState,
    (state) => state?.configurationCode?.public_code,
  );

  export const userGuide = createSelector(
    summaryState,
    (state) => state?.userGuide,
  );

  export const countries = createSelector(
    summaryState,
    (state) => state?.form?.countries,
  );

  export const salutations = createSelector(
    summaryState,
    (state) => state?.form?.salutations,
  );

  export const languages = createSelector(
    summaryState,
    (state) => state?.languages?.data,
  );

  export const currencies = createSelector(
    summaryState,
    (state) => state?.currencies?.data,
  );

  export const isLoading = createSelector(
    summaryState,
    (state) => state?.isLoading,
  );

  // only SO more details flag from store
  export const showMoreSoDetails = createSelector(
    summaryState,
    (state) => state.showMoreSoDetails,
  );

  export const userGuides = createSelector(
    summaryState,
    (state) => state?.userGuides?.data,
  );
}
