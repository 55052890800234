import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  ConfigurationService,
  Guide,
} from '@sales-libs/sc-configuration/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SlScConfigurationActions } from './configuration.actions';

@Injectable()
export class SlScConfigurationEffects {
  private actions$: Actions = inject(Actions);

  saveSelectedSalesOption$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScConfigurationActions.saveSelectedSalesOption),
      switchMap((payload) =>
        this.configurationService
          .putSalesOptions(
            payload.guideId,
            payload.userGuid,
            payload.language,
            payload.selectedSalesOptions,
          )
          .pipe(
            map((guidePayload: Guide) =>
              SlScConfigurationActions.saveSelectedSalesOptionSuccess({
                guide: guidePayload,
              }),
            ),
            catchError((err) =>
              of(
                SlScConfigurationActions.saveSelectedSalesOptionError({
                  payload: err.error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  saveDeselectedSalesOption$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScConfigurationActions.saveDeselectedSalesOption),
      switchMap((payload) =>
        this.configurationService
          .deleteSalesOptions(
            payload.guideId,
            payload.userGuid,
            payload.deselectedSalesOption,
            payload.language,
          )
          .pipe(
            map((guidePayload: Guide) =>
              SlScConfigurationActions.saveDeselectedSalesOptionSuccess({
                guide: guidePayload,
              }),
            ),
            catchError((err) =>
              of(
                SlScConfigurationActions.saveDeselectedSalesOptionError({
                  payload: err.error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  saveSelectedAttributes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScConfigurationActions.saveSelectedAttributes),
      switchMap((payload) =>
        this.configurationService
          .putGroupAttributes(
            payload.guideId,
            payload.userGuid,
            payload.language,
            payload.selectedAttributeValue,
          )
          .pipe(
            map((guidePayload: Guide) =>
              SlScConfigurationActions.saveSelectedAttributesSuccess({
                guide: guidePayload,
              }),
            ),
            catchError((err) =>
              of(
                SlScConfigurationActions.saveSelectedAttributesError({
                  payload: err.error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private configurationService: ConfigurationService,
    private readonly _router: Router,
  ) {}
}
