import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlScConfigurationState } from './configuration.store';

export namespace SlScConfigurationSelectors {
  export const configurationState =
    createFeatureSelector<SlScConfigurationState>('ConfigurationState');

  // get whole store
  export const configurationSection = createSelector(
    configurationState,
    (state) => state,
  );
  // only group loading flag from store
  export const isGroupLoading = createSelector(
    configurationState,
    (state) => state?.isGroupLoading,
  );
  // only debugger flag from store
  export const showDebugView = createSelector(
    configurationState,
    (state) => state?.showDebugView,
  );
  // only slider values from store
  export const selectedSliderValues = createSelector(
    configurationState,
    (state) => state?.sliderValues,
  );
  // only attribute submitting flag from store
  export const isAttributeSubmitting = createSelector(
    configurationState,
    (state) => state?.isAttributeSubmitting,
  );
  // only so value from store
  export const selectedSalesOptionName = createSelector(
    configurationState,
    (state) => state?.selectedSalesOptionName,
  );
  // only active color from store
  export const colorInfo = createSelector(configurationState, (state) => [
    state?.activeColor,
    state?.coloredPart,
  ]);
  // only model screenshot from store
  export const modelSS = createSelector(configurationState, (state) => [
    state?.modelImage,
  ]);
}
