<h2 mat-dialog-title>
  {{ 'turnkey_solution.summary.button_export' | translate }}
</h2>

<mat-dialog-content>
  <p>{{ 'turnkey_solution.summary.export_dialog_text' | translate }}</p>
  <mat-form-field
    class="full-width"
    *ngIf="supportedLanguages$ | async as supportedLanguages"
  >
    <mat-label>
      {{ 'turnkey_solution.common.select_language' | translate }}
    </mat-label>
    <mat-select
      [(ngModel)]="selectedLanguage"
      data-test="sc_language_dropdown"
      [disabled]="
        supportedLanguages.data !== undefined &&
        supportedLanguages.data !== null &&
        supportedLanguages.data.length <= 1
      "
      (selectionChange)="changeSelectedLanguage(selectedLanguage)"
    >
      <mat-option
        *ngFor="let supportedLanguage of supportedLanguages.data"
        [value]="supportedLanguage.code"
      >
        {{ supportedLanguage.native_name }} ({{ supportedLanguage.code }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="full-width"
    *ngIf="supportedCurrencies$ | async as supportedCurrencies"
  >
    <mat-label>
      {{ 'turnkey_solution.common.select_currency' | translate }}
    </mat-label>
    <mat-select
      [(ngModel)]="selectedCurrency"
      data-test="sc_currency_dropdown"
      [disabled]="supportedCurrencies.length <= 1"
      (selectionChange)="changeSelectedCurrency(selectedCurrency)"
    >
      <mat-option
        *ngFor="let supportedCurrency of supportedCurrencies"
        [value]="supportedCurrency.code"
      >
        {{ supportedCurrency.name }} ({{
          supportedCurrency.code ?? '' | slScCurrencySymbol
        }})
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" mat-dialog-close>
    {{ 'turnkey_solution.common.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="downloadPDF()"
    [disabled]="!!pdfDownloading"
    [dsButtonPending]="pdfDownloading"
    disableRipple="true"
    data-cy="sc-download-pdf-button"
    [gaClickListener]="Analytics.PdfDownload"
  >
    {{ 'turnkey_solution.summary.download_pdf' | translate }}
  </button>
</mat-dialog-actions>
