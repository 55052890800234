import { Texture } from "@babylonjs/core/Materials/Textures/texture.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_texture_transform";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_texture_transform/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_texture_transform {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadTextureInfoAsync(context, textureInfo, assign) {
    return GLTFLoader.LoadExtensionAsync(context, textureInfo, this.name, (extensionContext, extension) => {
      return this._loader.loadTextureInfoAsync(context, textureInfo, babylonTexture => {
        if (!(babylonTexture instanceof Texture)) {
          throw new Error(`${extensionContext}: Texture type not supported`);
        }
        if (extension.offset) {
          babylonTexture.uOffset = extension.offset[0];
          babylonTexture.vOffset = extension.offset[1];
        }
        // Always rotate around the origin.
        babylonTexture.uRotationCenter = 0;
        babylonTexture.vRotationCenter = 0;
        if (extension.rotation) {
          babylonTexture.wAng = -extension.rotation;
        }
        if (extension.scale) {
          babylonTexture.uScale = extension.scale[0];
          babylonTexture.vScale = extension.scale[1];
        }
        if (extension.texCoord != undefined) {
          babylonTexture.coordinatesIndex = extension.texCoord;
        }
        assign(babylonTexture);
      });
    });
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_texture_transform(loader));
