import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial.js";
import { GLTFLoader } from "../glTFLoader.js";
const NAME = "KHR_materials_clearcoat";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_materials_clearcoat/README.md)
 * [Playground Sample](https://www.babylonjs-playground.com/frame.html#7F7PN6#8)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_materials_clearcoat {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    /**
     * Defines a number that determines the order the extensions are applied.
     */
    this.order = 190;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
  }
  /**
   * @internal
   */
  loadMaterialPropertiesAsync(context, material, babylonMaterial) {
    return GLTFLoader.LoadExtensionAsync(context, material, this.name, (extensionContext, extension) => {
      const promises = new Array();
      promises.push(this._loader.loadMaterialPropertiesAsync(context, material, babylonMaterial));
      promises.push(this._loadClearCoatPropertiesAsync(extensionContext, extension, babylonMaterial));
      return Promise.all(promises).then(() => {});
    });
  }
  _loadClearCoatPropertiesAsync(context, properties, babylonMaterial) {
    if (!(babylonMaterial instanceof PBRMaterial)) {
      throw new Error(`${context}: Material type not supported`);
    }
    const promises = new Array();
    babylonMaterial.clearCoat.isEnabled = true;
    babylonMaterial.clearCoat.useRoughnessFromMainTexture = false;
    babylonMaterial.clearCoat.remapF0OnInterfaceChange = false;
    if (properties.clearcoatFactor != undefined) {
      babylonMaterial.clearCoat.intensity = properties.clearcoatFactor;
    } else {
      babylonMaterial.clearCoat.intensity = 0;
    }
    if (properties.clearcoatTexture) {
      promises.push(this._loader.loadTextureInfoAsync(`${context}/clearcoatTexture`, properties.clearcoatTexture, texture => {
        texture.name = `${babylonMaterial.name} (ClearCoat Intensity)`;
        babylonMaterial.clearCoat.texture = texture;
      }));
    }
    if (properties.clearcoatRoughnessFactor != undefined) {
      babylonMaterial.clearCoat.roughness = properties.clearcoatRoughnessFactor;
    } else {
      babylonMaterial.clearCoat.roughness = 0;
    }
    if (properties.clearcoatRoughnessTexture) {
      properties.clearcoatRoughnessTexture.nonColorData = true;
      promises.push(this._loader.loadTextureInfoAsync(`${context}/clearcoatRoughnessTexture`, properties.clearcoatRoughnessTexture, texture => {
        texture.name = `${babylonMaterial.name} (ClearCoat Roughness)`;
        babylonMaterial.clearCoat.textureRoughness = texture;
      }));
    }
    if (properties.clearcoatNormalTexture) {
      properties.clearcoatNormalTexture.nonColorData = true;
      promises.push(this._loader.loadTextureInfoAsync(`${context}/clearcoatNormalTexture`, properties.clearcoatNormalTexture, texture => {
        texture.name = `${babylonMaterial.name} (ClearCoat Normal)`;
        babylonMaterial.clearCoat.bumpTexture = texture;
      }));
      babylonMaterial.invertNormalMapX = !babylonMaterial.getScene().useRightHandedSystem;
      babylonMaterial.invertNormalMapY = babylonMaterial.getScene().useRightHandedSystem;
      if (properties.clearcoatNormalTexture.scale != undefined) {
        babylonMaterial.clearCoat.bumpTexture.level = properties.clearcoatNormalTexture.scale;
      }
    }
    return Promise.all(promises).then(() => {});
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_materials_clearcoat(loader));
