<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    class="sl-sc-expansion"
  >
    <mat-panel-title
      class="group-title"
      [ngClass]="{
        invalid:
          salesOptionGroup?.is_required && noSelectedSalesOptionOrAttribute
      }"
    >
      <strong>
        {{ salesOptionGroup?.name }}
        <span *ngIf="salesOptionGroup?.is_required">*</span>
      </strong>
    </mat-panel-title>
    <mat-panel-description fxLayoutAlign="flex-end center">
      <ng-container>
        {{ selectedSalesOption }}
      </ng-container>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-container
    *ngIf="salesOptionGroup?.type === SalesOptionGroupType.AttributeBased"
  >
    <sl-sc-sales-option-slider
      [attributes]="salesOptionGroup?.attributes"
      [salesOptionsGroupId]="salesOptionGroup?.id"
      [userGuid]="userGuid"
      [guide]="guide"
    ></sl-sc-sales-option-slider>
  </ng-container>

  <ng-container
    *ngIf="
      salesOptionGroup.subgroups && salesOptionGroup.subgroups.length > 0;
      else regularGroup
    "
  >
    <div *ngFor="let group of salesOptionGroup.subgroups; let last = last">
      <ng-container *ngIf="group?.type === SalesOptionGroupType.Subgroup">
        <sl-sc-sales-option-subgroup
          [salesOptionGroup]="group"
          [userGuid]="userGuid"
          [guide]="guide"
          [selectionType]="group?.selection_type"
          [subgroupType]="group.type"
          [sectionName]="sectionName"
          [class.last-subgroup]="last"
          data-test="so_subgroup"
        >
        </sl-sc-sales-option-subgroup>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #regularGroup>
    <ng-container
      *ngIf="salesOptionGroup?.type === SalesOptionGroupType.Regular"
      [ngSwitch]="salesOptionGroup?.selection_type"
    >
      <sl-sc-sales-option-list-multiple
        *ngSwitchCase="SalesOptionSelectionType.Checkbox"
        [salesOptions]="salesOptionGroup?.sales_options"
        [userGuid]="userGuid"
        [guide]="guide"
        [sectionName]="sectionName"
      ></sl-sc-sales-option-list-multiple>
      <sl-sc-sales-option-list
        *ngSwitchCase="SalesOptionSelectionType.RadioButton"
        [salesOptions]="salesOptionGroup?.sales_options"
        [salesOptionsGroupName]="salesOptionGroup?.name"
        [userGuid]="userGuid"
        [guide]="guide"
        [sectionName]="sectionName"
      ></sl-sc-sales-option-list>
      <sl-sc-sales-option-dropdown
        *ngSwitchCase="SalesOptionSelectionType.Dropdown"
        [userGuid]="userGuid"
        [guide]="guide"
      ></sl-sc-sales-option-dropdown>
      <sl-sc-sales-option-card
        *ngSwitchCase="SalesOptionSelectionType.Card"
        [userGuid]="userGuid"
        [guide]="guide"
      ></sl-sc-sales-option-card>
      <sl-sc-sales-option-ral-color
        *ngSwitchCase="SalesOptionSelectionType.RalColor"
        [salesOptions]="salesOptionGroup?.sales_options"
        [userGuid]="userGuid"
        [guide]="guide"
      ></sl-sc-sales-option-ral-color>
    </ng-container>
  </ng-template>
</mat-expansion-panel>
