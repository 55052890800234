import { NodeGeometryBlock } from "../../nodeGeometryBlock.js";
import { RegisterClass } from "../../../../Misc/typeStore.js";
import { NodeGeometryBlockConnectionPointTypes } from "../../Enums/nodeGeometryConnectionPointTypes.js";
import { GeometryInputBlock } from "../geometryInputBlock.js";
import { Matrix } from "../../../../Maths/math.vector.js";
/**
 * Block used to get a rotation matrix on X Axis
 */
export class RotationXBlock extends NodeGeometryBlock {
  /**
   * Create a new RotationXBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name);
    this.registerInput("angle", NodeGeometryBlockConnectionPointTypes.Float, false, 0);
    this.registerOutput("matrix", NodeGeometryBlockConnectionPointTypes.Matrix);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "RotationXBlock";
  }
  /**
   * Gets the angle input component
   */
  get angle() {
    return this._inputs[0];
  }
  /**
   * Gets the matrix output component
   */
  get matrix() {
    return this._outputs[0];
  }
  autoConfigure() {
    if (!this.angle.isConnected) {
      const angleInput = new GeometryInputBlock("Angle");
      angleInput.value = 0;
      angleInput.output.connectTo(this.angle);
    }
  }
  _buildBlock(state) {
    super._buildBlock(state);
    this.matrix._storedFunction = state => {
      return Matrix.RotationX(this.angle.getConnectedValue(state));
    };
  }
}
RegisterClass("BABYLON.RotationXBlock", RotationXBlock);
