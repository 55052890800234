import { Action, createReducer, on } from '@ngrx/store';
import { SlScSummaryActions } from './summary.actions';
import { SlScSummaryState, initialState } from './summary.store';

export const slScSummaryReducer = createReducer(
  initialState,

  on(
    SlScSummaryActions.getUserConfiguration,
    (state, { guideId, guid, language, currencyCode }) => ({
      ...initialState,
      guideId: guideId,
      guid: guid,
      language: language,
      currencyCode: currencyCode,
      isLoading: true,
    }),
  ),
  on(
    SlScSummaryActions.getUserConfigurationSuccess,
    (state, { userConfiguration }) => ({
      ...initialState,
      userConfiguration: userConfiguration,
      isLoading: false,
    }),
  ),
  on(SlScSummaryActions.getUserConfigurationError, (state, { payload }) => ({
    ...state,
    gettingUserConfigurationError: payload,
    isLoading: false,
  })),

  on(
    SlScSummaryActions.getConfigurationCode,
    (state, { guideId, guid, language, currencyCode }) => ({
      ...state,
      guideId: guideId,
      guid: guid,
      language: language,
      currencyCode: currencyCode,
    }),
  ),
  on(
    SlScSummaryActions.getConfigurationCodeSuccess,
    (state, { configurationCode }) => ({
      ...state,
      configurationCode: configurationCode,
    }),
  ),
  on(SlScSummaryActions.getConfigurationCodeError, (state, { payload }) => ({
    ...state,
    getConfigurationCodeError: payload,
  })),

  on(SlScSummaryActions.getSharedUserGuide, (state, { configurationCode }) => ({
    ...state,
    sharedConfigurationCode: configurationCode,
  })),
  on(SlScSummaryActions.getSharedUserGuideSuccess, (state, { userGuide }) => ({
    ...state,
    userGuide: userGuide,
  })),
  on(SlScSummaryActions.getSharedUserGuideError, (state, { payload }) => ({
    ...state,
    gettingUserGuideError: payload,
  })),

  on(SlScSummaryActions.getContactFormDetails, (state, { language }) => ({
    ...state,
    language: language,
  })),
  on(SlScSummaryActions.getContactFormDetailsSuccess, (state, { form }) => ({
    ...state,
    form: form,
  })),
  on(SlScSummaryActions.getContactFormDetailsError, (state, { payload }) => ({
    ...state,
    gettingContactFormDetailsError: payload,
  })),

  on(
    SlScSummaryActions.submitContactForm,
    (state, { guideId, guid, language, contactForm }) => ({
      ...state,
      guideId: guideId,
      guid: guid,
      language: language,
      contactForm: contactForm,
    }),
  ),
  on(SlScSummaryActions.submitContactFormSuccess, (state) => ({
    ...state,
  })),
  on(SlScSummaryActions.submitContactFormError, (state, { payload }) => ({
    ...state,
    submittingContactFormError: payload,
  })),
  on(SlScSummaryActions.getCurrencies, (state, { guideId }) => ({
    ...state,
    guideId: guideId,
  })),
  on(SlScSummaryActions.getCurrenciesSuccess, (state, { currencies }) => ({
    ...state,
    currencies: currencies,
  })),
  on(SlScSummaryActions.getCurrenciesError, (state, { payload }) => ({
    ...state,
    gettingCurrenciesError: payload,
  })),
  on(SlScSummaryActions.toggleSoMoreDetails, (state) => ({
    ...state,
    showMoreSoDetails: !state.showMoreSoDetails,
  })),
  on(SlScSummaryActions.resetToggleSoMoreDetails, (state) => ({
    ...state,
    showMoreSoDetails: false,
  })),
);

export function reducer(state: SlScSummaryState | undefined, action: Action) {
  return slScSummaryReducer(state, action);
}
