<div class="full-height" fxLayout="column" fxLayoutAlign="space-between">
  <div class="configuration-wrap">
    <form
      [formGroup]="configurationForm"
      *ngIf="configurationSections as sections"
      fxLayout="row wrap"
      fxFlex="1 1 auto"
    >
      <div
        fxLayout="row wrap"
        [fxFlex]="sectionName === summarySectionName ? 100 : 70"
        fxFlex.lt-md="100"
        class="configuration-nav-img"
      >
        <div
          [fxFlex]="sectionName === summarySectionName ? 100 : 70"
          fxFlex.lt-md="100"
          fxLayout="row wrap"
          [ngClass]="isSC ? 'nav-top' : 'nav-top-base'"
        >
          @if (isSC) {
            <div
              [fxFlex]="sectionName === summarySectionName ? 15 : 25"
              fxFlex.lt-lg="100"
              class="logo-container"
              fxLayout="row wrap"
              fxLayoutAlign="flex-start flex-start"
              fxLayoutAlign.lt-md="center center"
            >
              <sl-shared-sc-product-line-icons
                [productLineName]="scProductLineName"
                [selectedModelName]="this.selectedModelName"
                [urlFriendlyProductLineEnum]="urlFriendlyProductLineEnum"
                [environmentLink]="scUrl"
                (startNewConfiguration)="handleStartNewConfiguration()"
              ></sl-shared-sc-product-line-icons>
            </div>
          }

          <mat-tab-group
            mat-align-tabs="left"
            [fxFlex]="sectionName === summarySectionName ? 85 : 75"
            [fxFlex.lt-lg]="100"
            [fxFlex.lt-sm]="100"
            [selectedIndex]="currentStepIndex"
            (selectedIndexChange)="navigateToStep($event)"
          >
            <mat-tab
              *ngFor="let section of sections; let i = index"
              label="{{ section.name }}"
              [disabled]="i > requiredStepIndex && requiredStepIndex !== -1"
            >
              <ng-template
                matTabLabel
                *ngIf="
                  configurationSectionsValidity &&
                  !configurationSectionsValidity[i]
                "
              >
                <span
                  matBadge="!"
                  matBadgeOverlap="false"
                  matBadgeSize="small"
                  >{{ section.name }}</span
                >
              </ng-template>
            </mat-tab>
          </mat-tab-group>
          <ng-container *ngIf="sectionName === summarySectionName">
            <div [fxFlex]="100">
              <sl-sc-summary
                [isSC]="true"
                [scUrl]="scUrl"
                [summaryStepName]="summarySectionName"
                (backToConfigurationStep)="
                  navigateToConfigurationStep($event.sectionName)
                "
                (initNewStart)="handleStartNewConfiguration()"
              ></sl-sc-summary>
            </div>
          </ng-container>
          <div
            *ngIf="sectionName !== summarySectionName"
            class="model-img-wrap"
            fxLayoutAlign="center center"
          >
            @if (scProductLineName === urlFriendlyProductLineEnum.TimberCrane) {
              <button
                *ngIf="!displayModel"
                mat-stroked-button
                (click)="loadProductViewer()"
              >
                <mat-icon>visibility</mat-icon>
                {{ 'turnkey_solution.configuration.load_model' | translate }}
              </button>

              <sl-sc-product-viewer
                *ngIf="displayModel"
                #productViewerComponent
                [isGoToSummaryClicked]="goToSummaryClick"
              ></sl-sc-product-viewer>
            } @else {
              <img
                src="{{
                  (scProductLineName === urlFriendlyProductLineEnum.TailLift
                    ? modelImage
                    : sectionModelImage) ||
                    modelImage ||
                    '../../assets/img/placeholder-img.jpg'
                }}"
                alt="model image"
                class="model-img"
                priority
              />
            }
          </div>
        </div>
        <div
          *ngIf="sectionName !== summarySectionName"
          [fxFlex]="70"
          fxFlex.lt-md="100"
          class="nav-footer"
          fxLayout="row wrap"
          fxLayout.lt-xl="column wrap"
          fxLayoutAlign.xl="space-between center"
          fxLayoutAlign.xs="space-between center"
          [fxLayoutGap.gt-xs]="0.5 | dsSpacing"
        >
          <div
            fxLayout="row wrap"
            [fxLayoutGap]="0.5 | dsSpacing"
            fxLayoutAlign="space-between"
            fxLayoutAlign.xl="center baseline"
          >
            <button
              mat-stroked-button
              fxLayoutAlign="start center"
              fxFlexOrder.lt-sm="1"
              data-test="change_model_btn"
              (click)="navigateToSolution()"
              [gaClickListener]="Analytics.ChangeModel"
              [fxHide.xs]="true"
            >
              {{ 'turnkey_solution.configuration.change_model' | translate }}
            </button>
            <div class="total-units" fxFlexOrder.lt-sm="0">
              <ng-container
                *ngIf="
                  scProductLineName ===
                    urlFriendlyProductLineEnum.TimberCrane ||
                  scProductLineName === urlFriendlyProductLineEnum.ShortTimber
                "
              >
                <h4 data-test="sl_sc_weight">
                  {{ 'turnkey_solution.summary.total_weight' | translate }}:
                  {{ totalWeight }} {{ totalWeightUnit }}
                </h4>
                <h4>—</h4>
              </ng-container>
              <h4 *ngIf="showPrice" data-test="sl_sc_price">
                {{ 'turnkey_solution.summary.total_price' | translate }}:
                {{ totalPrice | currency: priceCurrency }}
              </h4>
            </div>
          </div>
          <div
            class="nav-btns-container"
            [fxLayoutGap]="1 | dsSpacing"
            fxLayoutAlign="space-between"
            fxLayoutAlign.xs="center center"
            fxLayout="row wrap"
          >
            <button
              mat-stroked-button
              *ngIf="currentStepIndex !== 0"
              (click)="navigateToStep(currentStepIndex - 1)"
              [fxHide.xs]="true"
              data-cy="back-btn"
            >
              {{ 'turnkey_solution.common.back' | translate }}
            </button>
            <div
              matTooltip="{{
                'turnkey_solution.configuration.summary_disabled' | translate
              }}"
              matTooltipPosition="above"
              [matTooltipDisabled]="!isDisabledSummaryButton"
              [fxHide.xs]="true"
            >
              <button
                mat-stroked-button
                [disabled]="isDisabledSummaryButton"
                (click)="navigateToSummary()"
                data-test="go_to_summary_btn"
                [gaClickListener]="Analytics.GoToSummary"
              >
                {{ 'turnkey_solution.configuration.go_to_summary' | translate }}
              </button>
            </div>
            <div [fxHide.gt-xs]="true">
              <button mat-icon-button [matMenuTriggerFor]="mobileMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #mobileMenu="matMenu">
                <button mat-menu-item (click)="navigateToSolution()">
                  {{
                    'turnkey_solution.configuration.change_model' | translate
                  }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="currentStepIndex !== 0"
                  (click)="navigateToStep(currentStepIndex - 1)"
                >
                  {{ 'turnkey_solution.common.back' | translate }}
                </button>
                <div
                  matTooltip="{{
                    'turnkey_solution.configuration.summary_disabled'
                      | translate
                  }}"
                  matTooltipPosition="below"
                  [matTooltipDisabled]="!isDisabledSummaryButton"
                >
                  <button
                    mat-menu-item
                    [disabled]="isDisabledSummaryButton"
                    (click)="navigateToSummary()"
                  >
                    {{
                      'turnkey_solution.configuration.go_to_summary' | translate
                    }}
                  </button>
                </div>
              </mat-menu>
            </div>

            <button
              mat-raised-button
              color="accent"
              [disabled]="!configurationSectionsValidity[currentStepIndex]"
              (click)="navigateToStep(currentStepIndex + 1)"
              data-cy="next-btn"
              class="btn-next"
            >
              {{ 'turnkey_solution.common.next' | translate }}
            </button>
          </div>
        </div>
      </div>
      <mat-card
        *ngIf="sectionName !== summarySectionName"
        class="configuration-details-wrap"
        fxFlex="30"
        fxFlex.lt-md="100"
      >
        <mat-card-content>
          <div
            class="sl-sc-sticky-header"
            fxLayout="column wrap"
            fxLayoutAlign="flex-end"
          >
            <h2
              fxLayoutAlign="center center"
              data-cy="model-name"
              [ngSwitch]="scProductLineName"
            >
              <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TailLift">
                {{ this.selectedModelName }}
              </ng-container>
              <ng-container
                *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane"
              >
                {{
                  'turnkey_solution.common.model_name.timber_crane' | translate
                }}
              </ng-container>
              <ng-container
                *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber"
              >
                {{
                  'turnkey_solution.common.model_name.short_timber' | translate
                }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'turnkey_solution.common.model_name.default' | translate }}
              </ng-container>
            </h2>
            <div
              *ngIf="guide.settings?.display_debugging_view"
              fxLayout="row"
              fxLayoutAlign="flex-end"
              fxLayoutAlign.lt-md="flex-start"
            >
              <mat-slide-toggle
                data-test="toggle-debug-view"
                (change)="toggleDebugView()"
                >{{
                  'turnkey_solution.debug_view.toggle_text' | translate
                }}</mat-slide-toggle
              >
            </div>
          </div>
          <sl-sc-sales-option-category-list
            *ngIf="(isLoading$ | async) === false; else salesOptionsLoading"
            [salesOptionCategories]="salesOptionCategories"
            [sectionName]="sectionName"
            [displayConfigurationDebuggingToggleFeatureFlag]="
              displayConfigurationDebuggingToggleFeatureFlag
            "
          ></sl-sc-sales-option-category-list>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
  @if (isSC && scSelectedLanguage) {
    <sl-shared-sc-footer
      *ngIf="scSelectedLanguage && sectionName !== summarySectionName"
      [language]="scSelectedLanguage"
    ></sl-shared-sc-footer>
  }
</div>
<ng-template #salesOptionsLoading>
  <div class="loader-wrap">
    <div fxLayout="row" fxLayoutAlign.gt-sm="flex-end">
      <ds-loading-placeholder
        [width]="4 | dsSpacing"
        [height]="1 | dsSpacing"
      ></ds-loading-placeholder>
    </div>
    <div fxLayout="column wrap" [fxLayoutGap]="1 | dsSpacing">
      <ds-loading-placeholder
        class="full-width"
        [height]="2 | dsSpacing"
      ></ds-loading-placeholder>
      <ds-loading-placeholder
        class="full-width"
        [height]="5 | dsSpacing"
      ></ds-loading-placeholder>
      <ds-loading-placeholder
        class="full-width"
        [height]="2 | dsSpacing"
      ></ds-loading-placeholder>
      <ds-loading-placeholder
        class="full-width"
        [height]="5 | dsSpacing"
      ></ds-loading-placeholder>
    </div>
  </div>
</ng-template>
