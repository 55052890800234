import { Vector3 } from "@babylonjs/core/Maths/math.vector.js";
import { Color3 } from "@babylonjs/core/Maths/math.color.js";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight.js";
import { PointLight } from "@babylonjs/core/Lights/pointLight.js";
import { SpotLight } from "@babylonjs/core/Lights/spotLight.js";
import { Light } from "@babylonjs/core/Lights/light.js";
import { GLTFLoader, ArrayItem } from "../glTFLoader.js";
const NAME = "KHR_lights_punctual";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Khronos/KHR_lights_punctual/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class KHR_lights {
  /**
   * @internal
   */
  constructor(loader) {
    /**
     * The name of this extension.
     */
    this.name = NAME;
    this._loader = loader;
    this.enabled = this._loader.isExtensionUsed(NAME);
  }
  /** @internal */
  dispose() {
    this._loader = null;
    delete this._lights;
  }
  /** @internal */
  onLoading() {
    const extensions = this._loader.gltf.extensions;
    if (extensions && extensions[this.name]) {
      const extension = extensions[this.name];
      this._lights = extension.lights;
      ArrayItem.Assign(this._lights);
    }
  }
  /**
   * @internal
   */
  loadNodeAsync(context, node, assign) {
    return GLTFLoader.LoadExtensionAsync(context, node, this.name, (extensionContext, extension) => {
      this._loader._allMaterialsDirtyRequired = true;
      return this._loader.loadNodeAsync(context, node, babylonMesh => {
        let babylonLight;
        const light = ArrayItem.Get(extensionContext, this._lights, extension.light);
        const name = light.name || babylonMesh.name;
        this._loader.babylonScene._blockEntityCollection = !!this._loader._assetContainer;
        switch (light.type) {
          case "directional" /* KHRLightsPunctual_LightType.DIRECTIONAL */:
            {
              const babylonDirectionalLight = new DirectionalLight(name, Vector3.Backward(), this._loader.babylonScene);
              babylonDirectionalLight.position.setAll(0);
              babylonLight = babylonDirectionalLight;
              break;
            }
          case "point" /* KHRLightsPunctual_LightType.POINT */:
            {
              babylonLight = new PointLight(name, Vector3.Zero(), this._loader.babylonScene);
              break;
            }
          case "spot" /* KHRLightsPunctual_LightType.SPOT */:
            {
              const babylonSpotLight = new SpotLight(name, Vector3.Zero(), Vector3.Backward(), 0, 1, this._loader.babylonScene);
              babylonSpotLight.angle = (light.spot && light.spot.outerConeAngle || Math.PI / 4) * 2;
              babylonSpotLight.innerAngle = (light.spot && light.spot.innerConeAngle || 0) * 2;
              babylonLight = babylonSpotLight;
              break;
            }
          default:
            {
              this._loader.babylonScene._blockEntityCollection = false;
              throw new Error(`${extensionContext}: Invalid light type (${light.type})`);
            }
        }
        babylonLight._parentContainer = this._loader._assetContainer;
        this._loader.babylonScene._blockEntityCollection = false;
        light._babylonLight = babylonLight;
        babylonLight.falloffType = Light.FALLOFF_GLTF;
        babylonLight.diffuse = light.color ? Color3.FromArray(light.color) : Color3.White();
        babylonLight.intensity = light.intensity == undefined ? 1 : light.intensity;
        babylonLight.range = light.range == undefined ? Number.MAX_VALUE : light.range;
        babylonLight.parent = babylonMesh;
        this._loader._babylonLights.push(babylonLight);
        GLTFLoader.AddPointerMetadata(babylonLight, extensionContext);
        assign(babylonMesh);
      });
    });
  }
}
GLTFLoader.RegisterExtension(NAME, loader => new KHR_lights(loader));
